import React, { Component, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import Basic from '../../../containers/wizard/Basic';

class FormWizard extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs='12' className='mb-5'>
            <h5 className='mb-4'>Basic</h5>
            <Basic />
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}
export default injectIntl(FormWizard);
