const canDo = (label, action) => {};
let main = {
  main: [
    {
      id: 'zoom',
      icon: 'iconsminds-chrysler-building',
      label: 'Zoommers',
      to: '/app/contributors/contributors/list',
      permission: canDo('admins', 'list'),
      custom: false,
      subs: [
        {
          icon: 'simple-icon-basket-loaded',
          label: 'Coordenador de implementação Zoom',
          to: '/app/contributors/implementations/list',
          permission: canDo('admins', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Orientadores ZOOM',
          to: `/app/orientadores/zoom`,
          permission: canDo('advisorsZoom', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Professores ZOOM',
          to: `/app/professores/zoom`,
          permission: canDo('teachersZoom', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Monitores ZOOM',
          to: `/app/monitores/zoom`,
          permission: canDo('monitorsZoom', 'list'),
          custom: false,
        },
      ],
    },
    {
      id: 'franchisees',
      icon: 'iconsminds-chrysler-building',
      label: 'Franquias',
      to: '/app/franquias/list',
      permission: canDo('franchisees', 'list'),
      custom: false,
      subs: [
        {
          icon: 'iconsminds-building',
          label: 'Franquias',
          to: '/app/franquias/list',
          permission: canDo('franchisees', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Gerente de franquias',
          to: '/app/franquias/gerentes/list',
          permission: canDo('franchiseeManagers', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Monitores de franquias',
          to: '/app/monitores/franquias',
          permission: canDo('franchiseeMonitors', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Orientadores Franquias',
          to: `/app/orientadores/franquia`,
          permission: canDo('advisorsFranchisees', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Professores Franquias',
          to: `/app/professores/franquias`,
          permission: canDo('teachersFranchisees', 'list'),
          custom: false,
        },
      ],
    },
    {
      id: 'schools',
      icon: 'iconsminds-building',
      label: 'Escolas',
      to: '/app/escolas/lista/main',
      permission: canDo('schools', 'list'),
      custom: false,
      subs: [
        {
          icon: 'iconsminds-building',
          label: 'Escolas',
          to: `/app/escolas/lista/main`,
          permission: canDo('schools', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Coordenadores',
          to: `/app/escolas/coordenadores/lista`,
          permission: canDo('schoolsCoordinators', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Diretores',
          to: `/app/escolas/diretores/lista`,
          permission: canDo('schoolsDirectors', 'list'),
          custom: false,
        },
      ],
    },
  ],

  customs: [
    {
      parentMenu: 'schools',
      on: '/app/escolas/escola/',
      splitNum: 4,
      subs: [
        {
          icon: 'iconsminds-building',
          label: 'Dados',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/escola/:id/dados`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Programas',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/escola/:id/programas`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Endereço de cobrança',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/escola/:id/endereco/cobranca`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Endereço de entrega',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/escola/:id/endereco/entrega`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Orientadores',
          permission: canDo('advisors', 'list'),
          to: `/app/escolas/escola/:id/orientadores/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Professores',
          permission: canDo('teachers', 'list'),
          to: `/app/escolas/escola/:id/professores/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Monitores',
          permission: canDo('teachers', 'list'),
          to: `/app/escolas/escola/:id/monitores/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Turmas',
          permission: canDo('classrooms', 'list'),
          to: `/app/escolas/escola/:id/turmas/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Cronogramas',
          permission: canDo('future-works', 'list'),
          to: `/app/escolas/escola/:id/cronogramas/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Atividades',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/escola/:id/atividades/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Alunos',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/escola/:id/alunos/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Coordenadores',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/escola/:id/coordenadores/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Diretores',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/escola/:id/diretores/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Voltar',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/lista/main`,
          custom: false,
        },
      ],
    },
    {
      parentMenu: 'materials',
      on: '/app/materiais/material/',
      splitNum: 4,
      subs: [
        {
          icon: 'iconsminds-building',
          label: 'Dados',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/dados`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Video',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/video`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Pdf',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/pdf`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Texto',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/texto`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Contexto',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/contexto`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Links de referência',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/links`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Desenvolvimento',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/desenvolvimento`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Conclusão',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/conclusao`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Adicionar item',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/novo`,
          custom: true,
        },
      ],
    },
  ],
};

export default main;
