import { combineReducers } from 'redux';

import authentication from './authentication';
import dynamicClasses from './dynamicClasses';
import customSub from './customSub';
import routes from './routes';
import resourceCreate from './resouceCreate';
import resourceUpdate from './resouceUpdate';
import resourceLists from './resourceLists';
import forgotPassword from './forgotPassword';
import materials from './materials';
import states from './states';
import menu_items from './menu_items';
import menu from './menu';
import trackUser from './trackUser';
import permissions from './permissions';

// From template
import settings from '../template-redux/settings/reducer';
// import menu from '../template-redux/menu/reducer';

export default combineReducers({
  authentication,
  dynamicClasses,
  customSub,
  routes,
  resourceLists,
  forgotPassword,
  settings,
  menu,
  resourceCreate,
  resourceUpdate,
  materials,
  states,
  menu_items,
  trackUser,
  permissions,
});
