import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import { Button, Card, FormGroup, Label, Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import store from '../../store';
import validationSchema from './forgotSchema';

const ForgotPassword = (props) => {
  const [emailSent, setEmailSent] = useState(false);

  return (
    <main>
      <Row className="h-100">
        <Colxx xxs="12" md="10" className="mx-auto my-auto">
          <Card className="auth-card">
            <div className="position-relative image-side "></div>
            {emailSent ? (
              <div className="form-side">
                <p className="logo-single" />
                <p
                  style={{
                    fontSize: '14px',
                    paddingBottom: '15px',
                  }}
                >
                  Foi enviado um email com instruções para alterar a senha.
                </p>
                <div
                  className="d-flex justify-content-center"
                  style={{ marginTop: '120px' }}
                >
                  <NavLink to={`/login`}>
                    <Button
                      color="primary"
                      className={`btn-shadow btn-multiple-state ${
                        props.authentication.loading ? 'show-spinner' : ''
                      }`}
                      size="lg"
                      type="submit"
                      style={{
                        width: '163px',
                        height: '40px',
                        fontSize: '14px',
                      }}
                    >
                      <span className="spinner d-inline-block">
                        <span className="bounce1" />
                        <span className="bounce2" />
                        <span className="bounce3" />
                      </span>

                      <p className="label">LOGIN</p>
                    </Button>
                  </NavLink>
                </div>
              </div>
            ) : (
              <div className="form-side">
                <p className="logo-single" />
                <p
                  style={{
                    fontSize: '14px',
                    textAlign: 'start',
                    paddingBottom: '15px',
                  }}
                >
                  Digite o email cadastrado e enviamos instruções de como
                  recuperar sua senha
                </p>
                <Formik
                  validationSchema={validationSchema({ email: '' })}
                  onSubmit={(values) => {
                    store.dispatch({
                      type: 'forgot_password/RESET_PASSWORD_REQUEST',
                      payload: {
                        email: values.email,
                      },
                      onComplete: ({ error, cancelled, data }) => {
                        console.log(props.forgotPassword);
                        if (error) {
                          console.log(error, cancelled, data);
                        } else {
                          setEmailSent(true);
                        }
                      },
                    });
                  }}
                  initialValues={{ email: '' }}
                >
                  {({ errors, touched, values }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <FormGroup className="form-group has-float-label">
                        <Label>E-mail </Label>
                        <Field
                          className="form-control"
                          name="email"
                          value={values.email}
                        />
                        {touched.email && errors.email && (
                          <div className="invalid-feedback d-block">
                            {errors.email}
                          </div>
                        )}
                      </FormGroup>

                      <div className="d-flex justify-content-between align-items-center">
                        <NavLink to={`/login`}>Login</NavLink>
                        <Button
                          color="primary"
                          className={`btn-shadow btn-multiple-state ${
                            props.authentication.loading ? 'show-spinner' : ''
                          }`}
                          size="lg"
                          type="submit"
                          style={{
                            width: '163px',
                            height: '40px',
                            fontSize: '14px',
                          }}
                        >
                          <span className="spinner d-inline-block">
                            <span className="bounce1" />
                            <span className="bounce2" />
                            <span className="bounce3" />
                          </span>
                          <p className="label">ENVIAR</p>
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
          </Card>
        </Colxx>
      </Row>
    </main>
  );
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    forgotPassword: state.forgotPassword,
  };
};
export default connect(mapStateToProps)(ForgotPassword);
