import paths from '../paths';

export default () => {
  console.log('paths', paths);
  const checkForHexRegExp = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i;
  let _path = window.document.location.pathname;
  let path = '';
  let base = { url: null, menu: null, submenu: null, form: null };
  _path.split('/').map((__path, index) => {
    if (index > 0) {
      if (!checkForHexRegExp.test(__path)) {
        path = path + '/' + __path;
      }
    }
  });
  console.log('path', path);
  let foundedPath = paths.filter((_path) => path.match(_path.url) !== null);
  foundedPath = foundedPath.pop();
  console.log('window path', foundedPath);
  base = { ...base, ...foundedPath };
  return base;
};
