export const Types = {
  SET_PERMISSIONS: 'permissions/SET_PERMISSIONS',
};

export default function reducer(state = {}, action) {
  switch (action.type) {
    case Types.SET_PERMISSIONS:
      console.log('aqui permissions', action);
      return action.payload;

    default:
      return state;
  }
}
