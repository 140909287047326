export const Types = {
  SET_SUB: 'customSub/SET_SUB',
};

export default function reducer(
  state = {
    parentMenu: null,
    items: [],
  },
  action
) {
  switch (action.type) {
    case Types.SET_SUB:
      console.log('payload action', action);
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
