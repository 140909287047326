import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import superagent from 'superagent';
import auth from '@feathersjs/authentication-client';

const feathersClient = feathers();

feathersClient
  .configure(rest(process.env.REACT_APP_BASE_API_URL).superagent(superagent))
  .configure(auth({ storage: window.localStorage, storageKey: 'plat' }));

feathersClient.on('reauthentication-error', function(error) {
  console.log('re error', error);
});

export default feathersClient;
