import { PermissibleRender } from '@brainhubeu/react-permissible';
import React from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';

/**
 * Para usar esse componente são necessários 3 parametros:
 *  path: caminho que a rota aponta
 *  render: Componenete a ser renderizado nessa rota
 *  requiredPermissions: um array contendo quais entities podem acessar essa rota
 *  permissionGroup: é um parametro string exclusivo para entityType contributors.
 * Segue exemplo de uso:
    <ProtectedRoute
        path={`${match.url}/franquias`}
        render={(props) => <Franchisees {...props} />}
        requiredPermissions={canDo('franchisees', 'list')}
        permissionGroup={'developments'}
    />
 */

const index = ({
  path,
  render,
  requiredPermissions,
  permissionGroup,
  authentication,
}) => {
  const AccessDenied = (props) => {
    return (
      <div>
        <h2>Acesso Negado</h2>
        <p>Seu usuário não possui permissões para acessar essa área.</p>
      </div>
    );
  };
  console.log(authentication);
  return (
    // <PermissibleRender
    //   userPermissions={[
    //     authentication.permission,
    //     authentication.user.entityType,
    //   ]}
    //   requiredPermissions={[...requiredPermissions, 'admins']}
    //   renderOtherwise={<AccessDenied />}
    //   oneperm
    // >
    <Route path={path} render={withRouter(render)} />
    // </PermissibleRender>
  );
};

const mapStateToProps = (state) => {
  console.log(state.authentication);
  return { authentication: state.authentication };
};

export default withRouter(connect(mapStateToProps, {})(index));
