const canDo = (label, action) => {};
let main = {
  main: [
    {
      id: 'materials',
      icon: 'iconsminds-books',
      label: 'Materiais',
      to: '/app/materiais/main',
      permission: canDo('materials', 'list'),
      custom: false,
      subs: [
        {
          icon: 'iconsminds-books',
          label: 'Materiais',
          to: `/app/materiais/main`,
          permission: canDo('materials', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-books',
          label: 'Customizar Jornadas',
          to: `/app/materiais/jornadas`,
          permission: canDo('materials', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-books',
          label: 'Customizar Trilhas',
          to: `/app/materiais/trilhas`,
          permission: canDo('materials', 'list'),
          custom: false,
        },
      ],
    },
    {
      id: 'shorters',
      icon: 'iconsminds-plane',
      label: 'Encurtador',
      to: '/app/encurtador/list',
      permission: canDo('shorters', 'list'),
      custom: false,
      subs: [],
    },
  ],

  customs: [
    {
      parentMenu: 'materials',
      on: '/app/materiais/material/',
      splitNum: 4,
      subs: [
        {
          icon: 'iconsminds-building',
          label: 'Dados',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/dados`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Video',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/video`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Pdf',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/pdf`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Texto',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/texto`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Contexto',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/contexto`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Links de referência',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/links`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Desenvolvimento',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/desenvolvimento`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Conclusão',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/conclusao`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Planejamento curricular',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/planejamento`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Descrição atividade',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/descricao`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Materiais',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/materiais`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Ponto de atenção',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/ponto-de-atencao`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Desenvolvimento',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/tr-desenvolvimento`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Adicionar item',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/novo`,
          custom: true,
        },
      ],
    },
  ],
};

export default main;
