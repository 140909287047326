export const Types = {
  SET_STATE_REQUEST: 'materials/SET_STATE_REQUEST',
  SET_STATE_SUCCESS: 'materials/SET_STATE_SUCCESS',
  SET_STATE_ERROR: 'materials/SET_STATE_ERROR',
};

export default function reducer(state = {}, action) {
  switch (action.type) {
    case Types.SET_STATE_REQUEST:
      return { ...state, loading: true };
    case Types.SET_STATE_SUCCESS:
      const newState = action.payload.state;
      return {
        ...newState,
      };
    case Types.SET_STATE_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
}
