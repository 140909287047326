import canDo from '../../canDo';

export const Types = {
  SET_MENU_REQUEST: 'menu/SET_MENU_REQUEST',
  SET_MENU_DEFAULT_REQUEST: 'menu/SET_MENU_DEFAULT_REQUEST',
  SET_MENU_SUCCESS: 'menu/SET_MENU_SUCCESS',
  SET_MENU_ERROR: 'menu/SET_MENU_ERROR',
};

function getInitialState() {
  return [
    {
      id: 'zoom',
      icon: 'iconsminds-chrysler-building',
      label: 'Zoommers',
      to: '/app/contributors/admins/list',
      permission: canDo('admins', 'list'),
      custom: false,
      subs: [
        {
          icon: 'simple-icon-basket-loaded',
          label: 'Todos',
          to: '/app/contributors/contributors/list',
          permission: canDo('admins', 'list'),
          custom: false,
        },
        {
          icon: 'simple-icon-briefcase',
          label: 'Administradores',
          // to: '/app/contributors',
          to: '/app/contributors/admins/list',
          permission: canDo('admins', 'list'),
          custom: false,
        },
        {
          icon: 'simple-icon-basket-loaded',
          label: 'Administrativo Zoom',
          to: '/app/contributors/administratives/list',
          permission: canDo('admins', 'list'),
          custom: false,
        },
        {
          icon: 'simple-icon-basket-loaded',
          label: 'Comercial Zoom',
          to: '/app/contributors/sellers/list',
          permission: canDo('admins', 'list'),
          custom: false,
        },
        {
          icon: 'simple-icon-basket-loaded',
          label: 'Desenvolvimento Zoom',
          to: '/app/contributors/developments/list',
          permission: canDo('admins', 'list'),
          custom: false,
        },
        {
          icon: 'simple-icon-basket-loaded',
          label: 'Implementação Zoom',
          to: '/app/contributors/implementations/list',
          permission: canDo('admins', 'list'),
          custom: false,
        },
        {
          icon: 'simple-icon-basket-loaded',
          label: 'Marketing Zoom',
          to: '/app/contributors/marketings/list',
          permission: canDo('admins', 'list'),
          custom: false,
        },
        // {
        //   icon: 'simple-icon-basket-loaded',
        //   label: 'Franquias Zoom',
        //   to: '/app/contributors/zfranchisees/list',
        //   permission: canDo('admins', 'list'),
        // },
      ],
    },
    {
      id: 'franchisees',
      icon: 'iconsminds-chrysler-building',
      label: 'Franquias',
      to: '/app/franquias/list',
      permission: canDo('franchisees', 'list'),
      custom: false,
      subs: [
        {
          icon: 'iconsminds-building',
          label: 'Franquias',
          to: '/app/franquias/list',
          permission: canDo('franchisees', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Gerente de franquias',
          to: '/app/franquias/gerentes/list',
          permission: canDo('franchiseeManagers', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Monitores de franquias',
          to: '/app/franquias/monitores/list/todos',
          permission: canDo('franchiseeMonitors', 'list'),
          custom: false,
        },
      ],
    },
    {
      id: 'schools',
      icon: 'iconsminds-building',
      label: 'Escolas',
      to: '/app/escolas/main',
      permission: canDo('schools', 'list'),
      custom: false,
      subs: [
        {
          icon: 'iconsminds-building',
          label: 'Escolas',
          to: `/app/escolas/main`,
          permission: canDo('schools', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Monitores de escolas',
          to: `/app/escolas/monitores/list/todos`,
          permission: canDo('schoolsMonitors', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Coordenadores',
          to: `/app/escolas/coordenadores`,
          permission: canDo('schoolsCoordinators', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Diretores',
          to: `/app/escolas/diretores`,
          permission: canDo('schoolsDirectors', 'list'),
          custom: false,
        },
      ],
    },
    {
      id: 'advisors',
      icon: 'simple-icon-people',
      label: 'Orientadores Zoom',
      to: '/app/orientadores/main',
      permission: canDo('advisors', 'list'),
      custom: false,
      subs: [
        {
          icon: 'iconsminds-building',
          label: 'Orientadores',
          to: `/app/orientadores/main`,
          permission: canDo('advisors', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Orientadores ZOOM',
          to: `/app/orientadores/zoom`,
          permission: canDo('advisorsZoom', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Orientadores Franquias',
          to: `/app/orientadores/franquia`,
          permission: canDo('advisorsFranchisees', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Orientadores Escolas',
          to: `/app/orientadores/escola`,
          permission: canDo('advisorsSchools', 'list'),
          custom: false,
        },
      ],
    },
    {
      id: 'teachers',
      icon: 'iconsminds-business-man',
      label: 'Professores Zoom',
      to: '/app/professores/main',
      permission: canDo('teachers', 'list'),
      custom: false,
      subs: [
        {
          icon: 'iconsminds-building',
          label: 'Professores',
          to: `/app/professores/main`,
          permission: canDo('teachers', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Professores ZOOM',
          to: `/app/professores/zoom`,
          permission: canDo('teachersZoom', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Professores Franquias',
          to: `/app/professores/franquias`,
          permission: canDo('teachersFranchisees', 'list'),
          custom: false,
        },
        // {
        //   icon: 'iconsminds-building',
        //   label: 'Professores Escolas',
        //   to: `/app/professores/escolas`,
        //   permission: canDo('teachersSchools', 'list'),
        //   custom: false,
        // },
      ],
    },
    {
      id: 'monitors',
      icon: 'iconsminds-business-man',
      label: 'Monitores Zoom',
      to: '/app/monitores/main',
      permission: canDo('monitors', 'list'),
      custom: false,
      subs: [
        {
          icon: 'iconsminds-building',
          label: 'Monitores',
          to: `/app/monitores/main`,
          permission: canDo('monitors', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Monitores ZOOM',
          to: `/app/monitores/zoom`,
          permission: canDo('monitorsZoom', 'list'),
          custom: false,
        },
        // {
        //   icon: 'iconsminds-building',
        //   label: 'Monitores Franquias',
        //   to: `/app/monitores/franquias`,
        //   permission: canDo('monitors', 'list'),
        // },
      ],
    },
    {
      id: 'students',
      icon: 'iconsminds-business-man',
      label: 'Alunos',
      to: '/app/alunos/main',
      permission: canDo('students', 'list'),
      custom: false,
      subs: [
        {
          icon: 'iconsminds-building',
          label: 'Alunos',
          to: `/app/alunos/main`,
          permission: canDo('students', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Alunos Extracurriculares',
          to: `/app/alunos/zoom`,
          permission: canDo('students', 'list'),
          custom: false,
        },
        // {
        //   icon: 'iconsminds-building',
        //   label: 'Alunos Franquias',
        //   to: `/app/alunos/franquias`,
        //   permission: canDo('students', 'list'),
        // },
        {
          icon: 'iconsminds-building',
          label: 'Alunos Escolas',
          to: `/app/alunos/escolas`,
          permission: canDo('students', 'list'),
          custom: false,
        },
      ],
    },
    {
      id: 'responsibles',
      icon: 'iconsminds-business-man',
      label: 'Responsaveis',
      to: '/app/responsaveis',
      permission: canDo('responsibles', 'list'),
      custom: false,
    },
    {
      id: 'materials',
      icon: 'iconsminds-books',
      label: 'Materiais',
      to: '/app/materiais/main',
      permission: canDo('materials', 'list'),
      custom: false,
      subs: [
        {
          icon: 'iconsminds-books',
          label: 'Materiais',
          to: `/app/materiais/main`,
          permission: canDo('materials', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-books',
          label: 'Customizar Jornadas',
          to: `/app/materiais/jornadas`,
          permission: canDo('materials', 'list'),
          custom: false,
        },
      ],
    },
    // {
    //   id: 'activities',
    //   icon: 'iconsminds-business-man',
    //   label: 'Atividades',
    //   to: '/app/atividades',
    //   permission: canDo('activities', 'list'),
    //   custom: false,
    // },
    // {
    //   id: 'jornadaz',
    //   icon: 'iconsminds-plane',
    //   label: 'Jornadas',
    //   to: '/app/jornadas',
    //   permission: canDo('activities', 'list'),
    //   custom: false,
    // },
    {
      id: 'microsoft-rooms',
      icon: 'iconsminds-plane',
      label: 'Sala Microsoft',
      to: '/app/microsoft-rooms/list',
      permission: canDo('microsoft-rooms', 'list'),
      custom: false,
      subs: [
        {
          icon: 'iconsminds-plane',
          label: 'Sala Microsoft',
          to: `/app/microsoft-rooms/list`,
          permission: canDo('microsoft-rooms', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Lista de aprovação',
          to: `/app/microsoft-rooms/aprovacao`,
          permission: canDo('microsoft-rooms', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Lista de aprovados',
          to: `/app/microsoft-rooms/aprovados`,
          permission: canDo('microsoft-rooms', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Lista de reprovados',
          to: `/app/microsoft-rooms/reprovados`,
          permission: canDo('microsoft-rooms', 'list'),
          custom: false,
        },
      ],
    },
    {
      id: 'shorters',
      icon: 'iconsminds-plane',
      label: 'Encurtador',
      to: '/app/encurtador/list',
      permission: canDo('shorters', 'list'),
      custom: false,
      subs: [
        // {
        //   icon: 'iconsminds-plane',
        //   label: 'Ativos',
        //   to: `/app/encurtador/list/true`,
        //   permission: canDo('shorters', 'list'),
        //   custom: false,
        // },
        // {
        //   icon: 'iconsminds-plane',
        //   label: 'Inativos',
        //   to: `/app/encurtador/list/false`,
        //   permission: canDo('shorters', 'list'),
        //   custom: false,
        // },
      ],
    },
    // {
    //   id: 'my-profile',
    //   icon: 'iconsminds-profile',
    //   label: 'Meu Perfil',
    //   to: '/app/minha-conta',
    //   permission: canDo('all', 'all'),
    //   custom: false,
    // },
  ];
}

export default function reducer(state = getInitialState(), action) {
  console.log(action);
  switch (action.type) {
    case Types.SET_MENU_REQUEST:
      return state;
    case Types.SET_MENU_DEFAULT_REQUEST:
      return getInitialState();
    case Types.SET_MENU_SUCCESS:
      const newState = action.payload.state;
      return newState;
    case Types.SET_MENU_ERROR:
      return { state, loading: false, error: action.error };
    default:
      return state;
  }
}
