import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { Provider } from 'react-redux';
import Routes from './routes';
import store from './store';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import feathers from './feathers';
import NotificationContainer from './components/common/react-notifications/NotificationContainer';

const App = () => {
  console.log({prod: process.env.PLATAFORMAZ_API})

  return (
    <div className='App'>
      <Provider store={store}>
        <NotificationContainer />
        <Router>
          <Routes />
        </Router>
      </Provider>
    </div>
  );
};

export default App;
