export const Types = {
  CHECK_AUTHED: 'authentication/CHECK_AUTHED',
  CHECK_AUTHED_SUCCESS: 'authentication/CHECK_AUTHED_SUCCESS',

  SIGN_IN_REQUEST: 'authentication/SIGN_IN_REQUEST',
  SIGN_IN_SUCCESS: 'authentication/SIGN_IN_SUCCESS',
  SIGN_IN_FAILED: 'authentication/SIGN_IN_FAILED',

  SIGN_OUT_REQUEST: 'authentication/SIGN_OUT_REQUEST',
  SIGN_OUT_SUCCESS: 'authentication/SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILED: 'authentication/SIGN_OUT_FAILED',
};

export default function reducer(
  state = {
    token: null,
    user: null,
    entity: null,
    loading: false,
    permission: null,
    error: '',
  },
  action
) {
  switch (action.type) {
    case Types.CHECK_AUTHED:
      return { ...state, loading: false };
    case Types.CHECK_AUTHED_SUCCESS:
      return {
        ...state,
        entity: action.payload.entity,
        user: action.payload.user,
        permission: action.payload.permission,
        loading: false,
      };
    case Types.SIGN_IN_REQUEST:
      return { ...state, loading: true };
    case Types.SIGN_IN_SUCCESS:
      return {
        token: action.payload.token,
        user: action.payload.user,
        entity: action.payload.entity,
        error: null,
        loading: false,
      };
    case Types.SIGN_IN_FAILED:
      return {
        ...state,
        token: null,
        user: null,
        entity: null,
        error: 'Erro ao logar',
        loading: false,
      };
    case Types.SIGN_OUT_REQUEST:
      return { ...state, loading: true };
    case Types.SIGN_OUT_SUCCESS:
      return {
        ...state,
        token: null,
        user: null,
        entity: null,
        error: null,
        loading: false,
      };
    case Types.SIGN_OUT_FAILED:
      return {
        ...state,
        token: null,
        user: null,
        entity: null,
        error: 'Por favor atualize a pagina e tente novamente',
        loading: false,
      };
    default:
      return state;
  }
}
