export default [
  {
    url: '/app/franquias/list',
    menu: 'franchisees',
    submenu: 'todos',
    form: null,
  },
  {
    url: '/app/franquias/form/',
    menu: 'franchisees',
    submenu: 'franchisee',
    form: 'franchisees',
  },
  {
    url: '/app/franquias/coordenadores/list',
    menu: 'franchisees',
    submenu: 'franchiseeCoordinators',
    form: null,
  },
  {
    url: '/app/franquias/coordenadores/form',
    menu: 'franchisees',
    submenu: 'franchiseeCoordinators',
    form: 'franchiseeCoordinators',
  },
  {
    url: '/app/monitores/franquias',
    menu: 'franchisees',
    submenu: 'franchiseeMonitors',
    form: null,
  },
  {
    url: '/app/monitores/franquia/novo',
    menu: 'franchisees',
    submenu: 'franchiseeMonitors',
    form: 'franchiseeMonitors',
  },
  {
    url: '/app/monitores/franquia/editar',
    menu: 'franchisees',
    submenu: 'franchiseeMonitors',
    form: 'franchiseeMonitors',
  },
  {
    url: '/app/orientadores/franquias',
    menu: 'franchisees',
    submenu: 'franchiseeAdvisors',
    form: null,
  },
  {
    url: '/app/orientadores/franquia/novo',
    menu: 'franchisees',
    submenu: 'franchiseeAdvisors',
    form: 'franchiseeAdvisors',
  },
  {
    url: '/app/orientadores/franquia/editar',
    menu: 'franchisees',
    submenu: 'franchiseeAdvisors',
    form: 'franchiseeAdvisors',
  },
];
