import React, { Component } from 'react';
import { useHistory } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Input,
} from 'reactstrap';

import { NavLink } from 'react-router-dom';
import { MobileMenuIcon, MenuIcon } from '../../components/svg';
import { useDispatch, useSelector } from 'react-redux';

export default (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.authentication.user);
  const dynamicClasses = useSelector((state) => state.dynamicClasses);
  const menu = useSelector((state) => state.menu);

  const handleLogout = () => {
    dispatch({
      type: 'authentication/SIGN_OUT_REQUEST',
    });
    history.push('/login');
  };

  const menuToggle = () => {
    if (menu.submenuOpened == true) {
      dispatch({
        type: 'menu/SUB_MENU_CLOSE',
      });
    } else {
      dispatch({
        type: 'menu/SUB_MENU_OPEN',
      });
    }
    // let containerClass = dynamicClasses.container;
    // let mainClass = dynamicClasses.main;
    // console.log(
    //   "containerClass.match('main-show-temporary')",
    //   containerClass.match('main-show-temporary')
    // );
    // if (containerClass.match('main-show-temporary') != null) {
    //   console.log('replace main');
    //   containerClass = containerClass.replace('main-show-temporary', '');
    //   mainClass = 'menu-default';
    // } else {
    //   containerClass = containerClass + ' main-show-temporary';
    //   mainClass = 'sub-hidden';
    // }
    // console.log('containerClass', containerClass);
    // dispatch({
    //   type: 'dynamicClasses/CHANGE_CLASS',
    //   payload: { container: containerClass, main: mainClass },
    // });
    // main-show-temporary
  };

  return (
    <nav className="navbar fixed-top">
      <div className="d-flex align-items-center navbar-left">
        <div
          className="menu-button d-none d-md-block"
          onClick={() => {
            menuToggle();
          }}
        >
          <MenuIcon />
        </div>
        <NavLink
          to="#"
          location={{}}
          className="menu-button-mobile d-xs-block d-sm-block d-md-none"
          onClick={() => {
            menuToggle();
          }}
        >
          <MobileMenuIcon />
        </NavLink>
      </div>
      <a className="navbar-logo" href="/">
        <span className="logo d-none d-xs-block" />
        <span className="logo-mobile d-block d-xs-none" />
      </a>
      <div className="navbar-right">
        <div className="user d-inline-block">
          <UncontrolledDropdown className="dropdown-menu-right">
            <DropdownToggle className="p-0" color="empty">
              <span className="name mr-1">{user ? user.email : ''}</span>
              <span>
                <img
                  alt="Profile"
                  src={
                    user.photo ? user.photo : '/assets/img/avatar-default.png'
                  }
                />
              </span>
            </DropdownToggle>
            <DropdownMenu className="mt-3" right>
              <DropdownItem
                onClick={() => {
                  history.push('/app/minha-conta');
                }}
              >
                Minha Conta
              </DropdownItem>
              {/* <DropdownItem>Features</DropdownItem>
                <DropdownItem>History</DropdownItem> */}
              <DropdownItem
                onClick={() => {
                  history.push('/app/alterar-senha');
                }}
              >
                Alterar senha
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => handleLogout()}>Sair</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    </nav>
  );
};
