import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  withRouter,
} from 'react-router-dom';
import AuthedRoute from './AuthedRoute';
import NotAuthedRoute from './NotAuthedRoute';
import UserLayout from '../layout/UserLayout';

import Login from '../pages/user/Login';
import ForgotPassword from '../pages/user/ForgotPassword';
import ChangeForgotPassword from '../pages/user/ChangeForgotPassword';

// From template
import ViewApp from '../pages/app';
import { IntlProvider } from 'react-intl';
import AppLocale from '../lang';

const Routes = (props) => {
  const currentAppLocale = AppLocale['en'];

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <Router>
        <Switch>
          <Redirect exact from={'/'} to={'/app'} />

          <AuthedRoute
            {...props}
            path="/app"
            component={withRouter(ViewApp)}
          ></AuthedRoute>

          <NotAuthedRoute exact path="/login">
            <UserLayout>
              <Login />
            </UserLayout>
          </NotAuthedRoute>
          <NotAuthedRoute exact path="/esqueci-minha-senha">
            <UserLayout>
              <ForgotPassword />
            </UserLayout>
          </NotAuthedRoute>
          <UserLayout>
            <NotAuthedRoute
              exact
              path="/redefinir-senha"
              component={ChangeForgotPassword}
            />
          </UserLayout>
        </Switch>
      </Router>
    </IntlProvider>
  );
};

export default withRouter(Routes);
