import { call, put } from 'redux-saga/effects';
import feathers from '../../feathers';

const _askPasswordChange = (email) => {
  return feathers
    .service('forgot-password')
    .create({ email })
    .then((response) => {
      console.log('response', response);
      return response;
    })
    .catch((err) => {
      console.log(err);
      return { error: err };
    });
};

const _changePassword = (newPassword, token) => {
  console.log(newPassword, token);
  return feathers
    .service('forgot-password')
    .update(token, { data: { newPassword: newPassword }, newPassword })
    .then((response) => {
      console.log('response', response);
      return response;
    })
    .catch((err) => {
      return { error: err };
    });
};

//-----------------------------------//

export function* askPasswordChange({ payload }) {
  const response = yield call(_askPasswordChange, payload.email.toLowerCase());
  console.log(response);
  if (!response.resetPasswordToken) {
    console.log('erro ao resetar');
    yield put({
      type: 'forgot_password/RESET_PASSWORD_FAILED',
      payload: { ...payload, error: 'E-mail não cadastrado' },
    });
    return false;
  } else {
    console.log('sucesso ao resetar', response);
    yield put({
      type: 'forgot_password/RESET_PASSWORD_SUCCESS',
    });
  }
}

export function* changePassword({ payload }) {
  const response = yield call(
    _changePassword,
    payload.newPassword,
    payload.token
  );
  console.log(response);
  if (response.error) {
    console.log('Erro ao mudar a senha', response);
    yield put({
      type: 'forgot_password/CHANGE_PASSWORD_FAILED',
      payload: { ...payload, error: response.error.message },
    });
  } else {
    console.log('Sucesso ao mudar a senha');
    yield put({
      type: 'forgot_password/CHANGE_PASSWORD_SUCCESS',
    });
  }
}
