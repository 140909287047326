import admins from './admins';
import sellers from './sellers';
import developments from './developments';
import implementations from './implementations';
import marketings from './marketings';

import franchiseeManagers from './franchisee-managers';
import franchiseeCoordinators from './franchisee-coordinators';

import schools from './schools';

import advisors from './advisors';
import advisorsZoom from './advisorsZoom';
import teachers from './teachers';
export default {
  admins,
  sellers,
  developments,
  implementations,
  marketings,
  'franchisee-coordinators': franchiseeCoordinators,
  'franchisee-managers': franchiseeManagers,
  advisors,
  advisorsZoom,
  teachers,
  schools,
};
