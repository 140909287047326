export default [
  {
    url: '/app/escolas/lista/main',
    menu: 'schools',
    submenu: 'schools',
    form: null,
  },
  {
    url: '/app/escolas/coordenadores/lista',
    menu: 'schools',
    submenu: 'schoolsCoordinators',
    form: null,
  },
  {
    url: '/app/escolas/coordenadores/form',
    menu: 'schools',
    submenu: 'schoolsCoordinators',
    form: 'schoolsCoordinators',
  },
  {
    url: '/app/escolas/diretores/lista',
    menu: 'schools',
    submenu: 'schoolsDirectors',
    form: null,
  },
  {
    url: '/app/escolas/diretores/form',
    menu: 'schools',
    submenu: 'schoolsDirectors',
    form: 'schoolsDirectors',
  },
  {
    url: '/app/escolas/escola/dados',
    menu: 'schools',
    submenu: 'schoolData',
    form: 'schools',
  },
  {
    url: '/app/escolas/escola/programas',
    menu: 'schools',
    submenu: 'schoolPrograms',
    form: 'schools',
  },
  {
    url: '/app/escolas/escola/endereco/cobranca',
    menu: 'schools',
    submenu: 'schoolBillingAddress',
    form: 'schools',
  },
  {
    url: '/app/escolas/escola/endereco/entrega',
    menu: 'schools',
    submenu: 'schoolShippingAddress',
    form: 'schools',
  },
  {
    url: '/app/escolas/escola/orientadores/lista',
    menu: 'schools',
    submenu: 'schoolAdvisors',
    form: null,
  },
  {
    url: '/app/escolas/escola/monitores/lista',
    menu: 'schools',
    submenu: 'schoolMonitors',
    form: null,
  },
  {
    url: '/app/escolas/escola/turmas/lista',
    menu: 'schools',
    submenu: 'schoolClassrooms',
    form: null,
  },
  {
    url: '/app/escolas/escola/cronogramas/lista',
    menu: 'schools',
    submenu: 'schoolFutureWorks',
    form: null,
  },
  {
    url: '/app/escolas/escola/atividades/lista',
    menu: 'schools',
    submenu: 'schoolWorks',
    form: null,
  },
  {
    url: '/app/escolas/escola/alunos/lista',
    menu: 'schools',
    submenu: 'schoolStudents',
    form: null,
  },
  {
    url: '/app/escolas/escola/coordenadores/lista',
    menu: 'schools',
    submenu: 'schoolCoordinators',
    form: null,
  },
  {
    url: '/app/escolas/escola/professores/lista',
    menu: 'schools',
    submenu: 'schoolTeachers',
    form: null,
  },
  {
    url: '/app/escolas/escola/diretores/lista',
    menu: 'schools',
    submenu: 'schoolDirectors',
    form: null,
  },
];
