export const Types = {
  RESOURCE_UPDATE_REQUEST: 'update/RESOURCE_UPDATE_REQUEST',
  RESOURCE_UPDATE_SUCCESS: 'update/RESOURCE_UPDATE_SUCCESS',
  RESOURCE_UPDATE_ERROR: 'update/RESOURCE_UPDATE_ERROR'
};

export default function reducer(
  state = {
    loading: false,
    error: null
  },
  action
) {
  switch (action.type) {
    case Types.RESOURCE_UPDATE_REQUEST:
      return { ...state, loading: true };
    case Types.RESOURCE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case Types.RESOURCE_UPDATE_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
}
