export const Types = {
  SET_URL: 'routes/SET_URL',
};

export default function reducer(
  state = {
    routes: [],
  },
  action
) {
  switch (action.type) {
    case Types.SET_URL:
      let routes = state.routes;
      if (routes.length > 3) {
        routes.shift();
      }
      routes.push(action.payload);
      return { routes };
    default:
      return state;
  }
}
