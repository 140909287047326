export const Types = {
  RESET_PASSWORD_REQUEST: 'forgot_password/RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'forgot_password/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILED: 'forgot_password/RESET_PASSWORD_FAILED',

  CHANGE_PASSWORD_REQUEST: 'forgot_password/CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'forgot_password/CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILED: 'forgot_password/CHANGE_PASSWORD_FAILED',
};

export default function reducer(
  state = {
    emailSent: false,
    loading: false,
    error: null,
    passwordChanged: false,
  },
  action
) {
  switch (action.type) {
    case Types.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        emailSent: true,
        loading: false,
      };
    case Types.RESET_PASSWORD_FAILED:
      return {
        ...state,
        error: 'Email não cadastrado ?',
        loading: false,
      };
    case Types.CHANGE_PASSWORD_REQUEST:
      return { ...state, loading: true };
    case Types.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordChanged: true,
        loading: false,
      };
    case Types.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        error: 'Token inválido',
        loading: false,
      };
    default:
      return state;
  }
}
