import React, { useState, useEffect } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const NotAuthedRoute = ({ component: Component, ...rest }) => {
  console.log(Component);
  const history = useHistory();
  const dispatch = useDispatch();
  const [authed, setAuthed] = useState(null);
  useEffect(() => {
    checkAuth();
  }, []);
  const checkAuth = async () => {
    dispatch({
      type: 'authentication/CHECK_AUTHED',
      onComplete: ({ error, cancelled, data }) => {
        if (data.error) {
          setAuthed(false);
        } else {
          history.push('/app');
        }
      },
    });
  };
  return (
    <>
      {authed == null && <div className='loading'></div>}
      {authed !== null && (
        <Route {...rest} render={(props) => <Component {...props} />} />
      )}
    </>
  );
};

export default NotAuthedRoute;
