const canDo = (label, action) => {};
let main = {
  main: [
    {
      id: 'franchisees',
      icon: 'iconsminds-chrysler-building',
      label: 'Franquias',
      to: '/app/franquias/list',
      permission: canDo('franchisees', 'list'),
      custom: false,
      subs: [
        {
          icon: 'iconsminds-building',
          label: 'Franquias',
          to: '/app/franquias/list',
          permission: canDo('franchisees', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Gerente de franquias',
          to: '/app/franquias/gerentes/list',
          permission: canDo('franchiseeManagers', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Monitores de franquias',
          to: '/app/franquias/monitores/list/todos',
          permission: canDo('franchiseeMonitors', 'list'),
          custom: false,
        },
      ],
    },
    {
      id: 'schools',
      icon: 'iconsminds-building',
      label: 'Escolas',
      to: '/app/escolas/lista/main',
      permission: canDo('schools', 'list'),
      custom: false,
      // subs: [
      //   {
      //     icon: 'iconsminds-building',
      //     label: 'Escolas',
      //     to: `/app/escolas/lista/main`,
      //     permission: canDo('schools', 'list'),
      //     custom: false,
      //   },
      // {
      //   icon: 'iconsminds-building',
      //   label: 'Monitores de escolas',
      //   to: `/app/escolas/monitores/lista`,
      //   permission: canDo('schoolsMonitors', 'list'),
      //   custom: false,
      // },
      // {
      //   icon: 'iconsminds-building',
      //   label: 'Coordenadores',
      //   to: `/app/escolas/coordenadores/lista`,
      //   permission: canDo('schoolsCoordinators', 'list'),
      //   custom: false,
      // },
      // {
      //   icon: 'iconsminds-building',
      //   label: 'Diretores',
      //   to: `/app/escolas/diretores/lista`,
      //   permission: canDo('schoolsDirectors', 'list'),
      //   custom: false,
      // },
      // ],
    },
  ],

  customs: [
    {
      parentMenu: 'schools',
      on: '/app/escolas/escola/',
      splitNum: 4,
      subs: [
        {
          icon: 'iconsminds-building',
          label: 'Dados',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/escola/:id/dados`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Programas',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/escola/:id/programas`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Endereço de cobrança',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/escola/:id/endereco/cobranca`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Endereço de entrega',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/escola/:id/endereco/entrega`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Orientadores Zoom',
          permission: canDo('advisors', 'list'),
          to: `/app/escolas/escola/:id/orientadores/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Monitores Zoom',
          permission: canDo('teachers', 'list'),
          to: `/app/escolas/escola/:id/monitores/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Turmas',
          permission: canDo('classrooms', 'list'),
          to: `/app/escolas/escola/:id/turmas/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Cronogramas',
          permission: canDo('future-works', 'list'),
          to: `/app/escolas/escola/:id/cronogramas/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Atividades',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/escola/:id/atividades/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Alunos',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/escola/:id/alunos/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Coordenadores',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/escola/:id/coordenadores/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Professores',
          permission: canDo('teachers', 'list'),
          to: `/app/escolas/escola/:id/professores/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Diretores',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/escola/:id/diretores/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Voltar',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/lista/main`,
          custom: false,
        },
      ],
    },
  ],
};

export default main;
