import React, { useState, useEffect } from 'react';
import { Nav, NavItem, Collapse, Input } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import completeMenuItems from '../../data/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';

const Sidebar = (props) => {
  const location = useLocation();
  const permission = useSelector((state) => state.authentication.permission);
  const user = useSelector((state) => state.authentication.user);
  const entity = useSelector((state) => state.authentication.entity);
  console.log('permission', permission);
  const dynamicClasses = useSelector((state) => state.dynamicClasses);
  const customSub = useSelector((state) => state.customSub);
  const routes = useSelector((state) => state.routes.routes);
  const submenuShow = useSelector((state) => state.menu.submenuShow);
  const dispatch = useDispatch();
  const [selectedMainMenu, setSelectedMainMenu] = useState(null);
  const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  const [subMenu, setSubMenu] = useState([]);
  const [useCustomSub, setUseCustomSub] = useState(false);
  let menuItems = completeMenuItems[permission];
  const customSubs = ['/app/escolas/escola/', '/app/materiais/material/'];
  console.log('completeMenuItems', completeMenuItems);
  if (permission == 'advisors') {
    console.log('entity', entity);
    if (entity.ownBase == true) {
      menuItems = completeMenuItems['advisorsZoom'];
    }
  }
  if (permission == 'schools') {
    let id = user.entityId;
    let _subsWithId = menuItems.main.map((schoolMain) => {
      let splitedSub = schoolMain.to.split('/');
      splitedSub[4] = id;
      // sub.to = sub.to.replace(':id', id);
      schoolMain.to = splitedSub.join('/');

      return schoolMain;
    });
    menuItems.main = _subsWithId;
  }
  // useEffect(() => {
  //   if (selectedMainMenu == null) {
  //     menuItems.find((menuItem) => menuItem.to == routes[routes.length -1])
  //   }
  // }, [selectedMainMenu])
  useEffect(() => {
    console.log('location.path', location.pathname);
    let isCustom = false;
    let customCopy = { ...menuItems };
    customCopy.customs =
      customCopy.customs == undefined ? [] : customCopy.customs;
    customCopy.customs.map((customMenu) => {
      console.log('customMenu', customMenu.on);
      if (location.pathname.match(customMenu.on)) {
        let mainMenuItem = menuItems.main.find(
          (menuItem) => menuItem.id == customMenu.parentMenu
        );
        let id = location.pathname.split('/')[customMenu.splitNum];
        let subsWithId = customMenu.subs.map((sub) => {
          if (sub.custom) {
            let splitedSub = sub.to.split('/');
            splitedSub[customMenu.splitNum] = id;
            // sub.to = sub.to.replace(':id', id);
            sub.to = splitedSub.join('/');
          }
          return sub;
        });
        isCustom = true;
        // setSelectedMainMenu(mainMenuItem);
        selectMenu(mainMenuItem);
        setSubMenu(subsWithId);
        if (subsWithId.length > 0) {
          dispatch({
            type: 'menu/SUB_MENU_OPEN',
          });
        } else {
          dispatch({
            type: 'menu/SUB_MENU_CLOSE',
          });
        }
        subsWithId.map((sub) => {
          if (sub.to == location.pathname) {
            selectSubMenu(sub);
          }
        });
      }
    });
    if (isCustom == false) {
      let selected = false;
      menuItems.main.map((mainMenuItem) => {
        if (selected == false) {
          if (mainMenuItem.to == location.pathname) {
            selectMenu(mainMenuItem);
            selected = true;
          }
          if (mainMenuItem.subs != undefined && selected == false) {
            mainMenuItem.subs.map((sub) => {
              if (sub.to == location.pathname) {
                selectSubMenu(sub);
              }
            });
          }
        }
      });
    }
  }, [location]);

  // useEffect(() => {
  //   let _customSub = customSubs.find((customSub) =>
  //     location.pathname.match(customSub)
  //   );
  //   if (_customSub !== undefined) {
  //     console.log('use custom subs');
  //     // customSub
  //     if (customSub.items.length > 0) {
  //       let mainMenu = menuItems.main.find(
  //         (menuItem) => menuItem.id == customSub.parentMenu
  //       );
  //       if (mainMenu != undefined) {
  //         selectMenu(mainMenu);
  //         customSub.items.map((__customSub) => {
  //           console.log('__customSub.to', __customSub.to);
  //           console.log('location.to', location.pathname);
  //           if (__customSub.to == location.pathname) {
  //             selectSubMenu(__customSub);
  //             // setSelectedSubMenu(__customSub);
  //           }
  //         });
  //         setSubMenu(customSub.items);
  //       } else {
  //         console.log('use normal subs');
  //         if (selectedMainMenu !== null) {
  //           setSubMenu(selectedMainMenu.subs);
  //         }
  //       }
  //     }
  //     // setUseCustomSub(true);
  //   } else {
  //     console.log('use normal subs');
  //     if (selectedMainMenu !== null) {
  //       setSubMenu(selectedMainMenu.subs);
  //     }
  //   }
  // }, [customSub]);

  const selectMenu = (item) => {
    setSelectedMainMenu(item);
    setSubMenu(item.subs);
    if (item.subs == undefined || item.subs.length == 0) {
      dispatch({
        type: 'menu/SUB_MENU_CLOSE',
      });
    } else {
      dispatch({
        type: 'menu/SUB_MENU_OPEN',
      });
    }
    // let mainClass = '';
    // let containerClass = dynamicClasses.container;
    // if (item.subs == undefined || item.subs.length == 0) {
    //   mainClass = 'sub-hidden';
    //   containerClass = containerClass + ' main-show-temporary';
    // } else {
    //   let sub = item.subs.find((_sub) => _sub.to == item.to);
    //   console.log('__sub', sub);
    //   if (sub !== undefined) {
    //     setSelectedSubMenu(sub);
    //   }
    //   containerClass = containerClass.replace('main-show-temporary', '');
    // }
    // dispatch({
    //   type: 'dynamicClasses/CHANGE_CLASS',
    //   payload: { main: mainClass, container: containerClass },
    // });
  };

  const selectSubMenu = (item) => {
    // setSelectedMainMenu(item);
    setSelectedSubMenu(item);
    if (item.subs && item.subs.length > 0) {
      setSubMenu(item.subs);
    }
  };

  return (
    <div className="sidebar">
      <div className="main-menu">
        <div className="scroll">
          <PerfectScrollbar
            options={{ suppressScrollX: true, wheelPropagation: false }}
          >
            <Nav vertical className="list-unstyled">
              {menuItems &&
                menuItems.main.map((item) => {
                  return (
                    <NavItem
                      key={item.to}
                      className={selectedMainMenu == item ? 'active' : ''}
                      // className={classnames({
                      //   active:
                      //     (selectedParentMenu === item.id &&
                      //       viewingParentMenu === '') ||
                      //     viewingParentMenu === item.id,
                      // })}
                      onClick={() => {
                        // console.log(item.custom);
                        selectMenu(item);
                        // if (!item.custom || !item.subs) {
                        //   store.dispatch({
                        //     type: 'menu/SET_MENU_DEFAULT_REQUEST',
                        //     onComplete: ({ error, cancelled, data }) => {
                        //       console.log('terminou');
                        //       if (error) {
                        //         console.log(error, cancelled, data);
                        //       } else {
                        //         console.log(error, cancelled, data);
                        //       }
                        //     },
                        //   });
                        // }
                      }}
                    >
                      {item.newWindow ? (
                        <a
                          href={item.to}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <i className={item.icon} /> {item.label}
                        </a>
                      ) : (
                        <NavLink
                          to={item.to}
                          onClick={(e) => {
                            //alert(item.to);
                            //this.openSubMenu(e, item);
                            // console.log(this.props);
                          }}
                          data-flag={item.id}
                        >
                          <i className={item.icon} /> {item.label}
                        </NavLink>
                      )}
                    </NavItem>
                    // </PermissibleRender>
                  );
                })}
            </Nav>
          </PerfectScrollbar>
        </div>
      </div>
      {subMenu !== undefined && subMenu.length > 0 && (
        <div className="sub-menu" style={{ display: 'flex' }}>
          <div className="scroll">
            <PerfectScrollbar
              options={{ suppressScrollX: true, wheelPropagation: false }}
            >
              <Nav
                key={selectedMainMenu.to}
                className={{ 'd-block': true }}
                data-parent={selectedMainMenu.id}
              >
                {subMenu.map((sub, index) => {
                  if (
                    sub.initialHidden == undefined ||
                    sub.initialHidden == false ||
                    submenuShow.includes(sub.to)
                  ) {
                    return (
                      <NavItem
                        key={`${sub.id}_${index}`}
                        className={sub == selectedSubMenu ? 'active' : ''}
                        // className={`${
                        //   sub.subs && sub.subs.length > 0 ? 'has-sub-item' : ''
                        // }`}
                        onClick={() => {
                          selectSubMenu(sub);
                          // console.log(
                          //   'menu',
                          //   item,
                          //   sub,
                          //   this.props.authentication.user.entityType
                          // );
                          // if (!sub.custom) {
                          // store.dispatch({
                          //   type: 'menu/SET_MENU_DEFAULT_REQUEST',
                          //   onComplete: ({ error, cancelled, data }) => {
                          //     console.log('terminou');
                          //     if (error) {
                          //       console.log(error, cancelled, data);
                          //     } else {
                          //       console.log(error, cancelled, data);
                          //     }
                          //   },
                          // });
                          // }
                        }}
                      >
                        <NavLink to={sub.to}>
                          <i className={sub.icon} /> {sub.label}
                        </NavLink>
                      </NavItem>
                    );
                  }
                })}
              </Nav>
            </PerfectScrollbar>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
