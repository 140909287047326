import { call, put } from 'redux-saga/effects';
import listInfos from '../listInfos';
import feathers from '../../feathers';

const _update = async (_resource, payload) => {
  console.log(payload);
  return feathers
    .service(_resource.service)
    .patch(payload.id, payload.data)
    .then((response) => {
      console.log('response', response);
      return response;
    });
};

export function* update({ payload }) {
  const _resource = listInfos.find(
    (listInfos) => listInfos.name === payload.name
  );
  if (_resource) {
    console.log(payload.id, payload.data);

    const resource = yield call(_update, _resource, payload);
    yield put({ type: 'update/RESOURCE_UPDATE_SUCCESS' });
    return resource;
  } else {
    //   error resource not found
    yield put({
      type: 'update/RESOURCE_UPDATE_ERROR',
      error: 'not found',
    });
  }
}
