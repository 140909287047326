const canDo = (label, action) => {};
let main = {
  main: [
    {
      id: 'zoom',
      icon: 'iconsminds-chrysler-building',
      label: 'Zoommers',
      to: '/app/contributors/contributors/list',
      permission: canDo('admins', 'list'),
      custom: false,
      subs: [
        {
          icon: 'simple-icon-basket-loaded',
          label: 'Todos',
          to: '/app/contributors/contributors/list',
          permission: canDo('admins', 'list'),
          custom: false,
        },
        {
          icon: 'simple-icon-briefcase',
          label: 'Administradores',
          // to: '/app/contributors',
          to: '/app/contributors/admins/list',
          permission: canDo('admins', 'list'),
          custom: false,
        },
        {
          icon: 'simple-icon-basket-loaded',
          label: 'Administrativo Zoom',
          to: '/app/contributors/administratives/list',
          permission: canDo('admins', 'list'),
          custom: false,
        },
        {
          icon: 'simple-icon-basket-loaded',
          label: 'Comercial Zoom',
          to: '/app/contributors/sellers/list',
          permission: canDo('admins', 'list'),
          custom: false,
        },
        {
          icon: 'simple-icon-basket-loaded',
          label: 'Desenvolvimento Zoom',
          to: '/app/contributors/developments/list',
          permission: canDo('admins', 'list'),
          custom: false,
        },
        {
          icon: 'simple-icon-basket-loaded',
          label: 'Implementação Zoom',
          to: '/app/contributors/implementations/list',
          permission: canDo('admins', 'list'),
          custom: false,
        },
        {
          icon: 'simple-icon-basket-loaded',
          label: 'Marketing Zoom',
          to: '/app/contributors/marketings/list',
          permission: canDo('admins', 'list'),
          custom: false,
        },
      ],
    },
    {
      id: 'franchisees',
      icon: 'iconsminds-chrysler-building',
      label: 'Franquias',
      to: '/app/franquias/list',
      permission: canDo('franchisees', 'list'),
      custom: false,
      subs: [
        {
          icon: 'iconsminds-building',
          label: 'Franquias',
          to: '/app/franquias/list',
          permission: canDo('franchisees', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Gerente de franquias',
          to: '/app/franquias/gerentes/list',
          permission: canDo('franchiseeManagers', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Monitores de franquias',
          to: '/app/franquias/monitores/list/todos',
          permission: canDo('franchiseeMonitors', 'list'),
          custom: false,
        },
      ],
    },
    {
      id: 'schools',
      icon: 'iconsminds-building',
      label: 'Escolas',
      to: '/app/escolas/lista/main',
      permission: canDo('schools', 'list'),
      custom: false,
      subs: [
        {
          icon: 'iconsminds-building',
          label: 'Escolas',
          to: `/app/escolas/lista/main`,
          permission: canDo('schools', 'list'),
          custom: false,
        },
        // {
        //   icon: 'iconsminds-building',
        //   label: 'Monitores de escolas',
        //   to: `/app/escolas/monitores/lista`,
        //   permission: canDo('schoolsMonitors', 'list'),
        //   custom: false,
        // },
        {
          icon: 'iconsminds-building',
          label: 'Coordenadores',
          to: `/app/escolas/coordenadores/lista`,
          permission: canDo('schoolsCoordinators', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Diretores',
          to: `/app/escolas/diretores/lista`,
          permission: canDo('schoolsDirectors', 'list'),
          custom: false,
        },
      ],
    },
    {
      id: 'advisors',
      icon: 'simple-icon-people',
      label: 'Orientadores Zoom',
      to: '/app/orientadores/main',
      permission: canDo('advisors', 'list'),
      custom: false,
      subs: [
        {
          icon: 'iconsminds-building',
          label: 'Orientadores',
          to: `/app/orientadores/main`,
          permission: canDo('advisors', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Orientadores ZOOM',
          to: `/app/orientadores/zoom`,
          permission: canDo('advisorsZoom', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Orientadores Franquias',
          to: `/app/orientadores/franquia`,
          permission: canDo('advisorsFranchisees', 'list'),
          custom: false,
        },
        // {
        //   icon: 'iconsminds-building',
        //   label: 'Orientadores Escolas',
        //   to: `/app/orientadores/escola`,
        //   permission: canDo('advisorsSchools', 'list'),
        //   custom: false,
        // },
      ],
    },
    {
      id: 'teachers',
      icon: 'iconsminds-business-man',
      label: 'Professores Zoom',
      to: '/app/professores/main',
      permission: canDo('teachers', 'list'),
      custom: false,
      subs: [
        {
          icon: 'iconsminds-building',
          label: 'Professores',
          to: `/app/professores/main`,
          permission: canDo('teachers', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Professores Zoom',
          to: `/app/professores/zoom`,
          permission: canDo('teachersZoom', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Professores Franquias',
          to: `/app/professores/franquias`,
          permission: canDo('teachersFranchisees', 'list'),
          custom: false,
        },
        // {
        //   icon: 'iconsminds-building',
        //   label: 'Professores Escolas',
        //   to: `/app/professores/escolas`,
        //   permission: canDo('teachersSchools', 'list'),
        //   custom: false,
        // },
      ],
    },
    {
      id: 'monitors',
      icon: 'iconsminds-business-man',
      label: 'Monitores Zoom',
      to: '/app/monitores/main',
      permission: canDo('monitors', 'list'),
      custom: false,
      subs: [
        {
          icon: 'iconsminds-building',
          label: 'Monitores',
          to: `/app/monitores/main`,
          permission: canDo('monitors', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Monitores ZOOM',
          to: `/app/monitores/zoom`,
          permission: canDo('monitorsZoom', 'list'),
          custom: false,
        },
      ],
    },
    {
      id: 'students',
      icon: 'iconsminds-business-man',
      label: 'Alunos',
      to: '/app/alunos/main',
      permission: canDo('students', 'list'),
      custom: false,
      subs: [
        {
          icon: 'iconsminds-building',
          label: 'Alunos',
          to: `/app/alunos/main`,
          permission: canDo('students', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Alunos Extracurriculares',
          to: `/app/alunos/zoom`,
          permission: canDo('students', 'list'),
          custom: false,
        },
        {
          icon: 'iconsminds-building',
          label: 'Alunos Escolas',
          to: `/app/alunos/escolas`,
          permission: canDo('students', 'list'),
          custom: false,
        },
      ],
    },
    {
      id: 'responsibles',
      icon: 'iconsminds-business-man',
      label: 'Responsaveis',
      to: '/app/responsaveis/main',
      permission: canDo('responsibles', 'list'),
      custom: false,
    },
    {
      id: 'news',
      icon: 'iconsminds-business-man',
      label: 'Noticias',
      to: '/app/noticias/main',
      permission: canDo('news', 'list'),
      custom: false,
    },
    {
      id: 'family-chats',
      icon: 'iconsminds-business-man',
      label: 'Batepapo familia',
      to: '/app/batepapo/main',
      permission: canDo('family-chats', 'list'),
      custom: false,
    },
    {
      id: 'shorters',
      icon: 'iconsminds-plane',
      label: 'Encurtador',
      to: '/app/encurtador/list',
      permission: canDo('shorters', 'list'),
      custom: false,
      subs: [],
    },
  ],

  customs: [
    {
      parentMenu: 'schools',
      on: '/app/escolas/escola/',
      splitNum: 4,
      subs: [
        {
          icon: 'iconsminds-building',
          label: 'Dados',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/escola/:id/dados`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Programas',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/escola/:id/programas`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Endereço de cobrança',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/escola/:id/endereco/cobranca`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Endereço de entrega',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/escola/:id/endereco/entrega`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Orientadores Zoom',
          permission: canDo('advisors', 'list'),
          to: `/app/escolas/escola/:id/orientadores/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Monitores Zoom',
          permission: canDo('teachers', 'list'),
          to: `/app/escolas/escola/:id/monitores/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Turmas',
          permission: canDo('classrooms', 'list'),
          to: `/app/escolas/escola/:id/turmas/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Cronogramas',
          permission: canDo('future-works', 'list'),
          to: `/app/escolas/escola/:id/cronogramas/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Atividades',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/escola/:id/atividades/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Alunos',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/escola/:id/alunos/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Coordenadores',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/escola/:id/coordenadores/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Professores',
          permission: canDo('teachers', 'list'),
          to: `/app/escolas/escola/:id/professores/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Diretores',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/escola/:id/diretores/lista`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Voltar',
          permission: canDo('schools', 'list'),
          to: `/app/escolas/lista/main`,
          custom: false,
        },
      ],
    },
    {
      parentMenu: 'materials',
      on: '/app/materiais/material/',
      splitNum: 4,
      subs: [
        {
          icon: 'iconsminds-building',
          label: 'Dados',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/dados`,
          custom: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Video',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/video`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Pdf',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/pdf`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Texto',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/texto`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Contexto',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/contexto`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Links de referência',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/links`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Desenvolvimento',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/desenvolvimento`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Conclusão',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/conclusao`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Planejamento curricular',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/planejamento`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Descrição atividade',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/descricao`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Materiais',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/materiais`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Ponto de atenção',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/ponto-de-atencao`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Desenvolvimento',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/tr-desenvolvimento`,
          custom: true,
          initialHidden: true,
        },
        {
          icon: 'iconsminds-building',
          label: 'Adicionar item',
          permission: canDo('schools', 'list'),
          to: `/app/materiais/material/:id/novo`,
          custom: true,
        },
      ],
    },
  ],
};

export default main;
