export default [
  {
    name: 'contributors',
    path: '/contributors',
    service: 'contributors',
    query: {},
  },
  {
    name: 'admins',
    path: '/contributors',
    service: 'contributors',
    query: { permissionGroup: 'admins' },
  },
  {
    name: 'administratives',
    path: '/contributors',
    service: 'contributors',
    query: { permissionGroup: 'administratives' },
  },
  {
    name: 'sellers',
    path: '/contributors',
    service: 'contributors',
    query: { permissionGroup: 'sellers' },
  },
  {
    name: 'developments',
    path: '/contributors',
    service: 'contributors',
    query: { permissionGroup: 'developments' },
  },
  {
    name: 'implementations',
    path: '/contributors',
    service: 'contributors',
    query: { permissionGroup: 'implementations' },
  },
  {
    name: 'marketings',
    path: '/contributors',
    service: 'contributors',
    query: { permissionGroup: 'marketings' },
  },
  {
    name: 'franchisees',
    path: '/franchisees',
    service: 'franchisees',
  },
  {
    name: 'franchisee-managers',
    path: '/franchisee-managers',
    service: 'franchisee-managers',
  },
  {
    name: 'schools',
    path: '/schools',
    service: 'schools',
  },
  {
    name: 'advisors',
    path: '/advisors',
    service: 'advisors',
  },
  {
    name: 'zoomAdvisors',
    path: '/advisors',
    service: 'advisors',
    query: { own_base: 'true' },
  },
  {
    name: 'materials',
    path: '/materials',
    service: 'materials',
  },
  {
    name: 'journeys',
    path: '/journeys',
    service: 'journeys',
  },
  {
    name: 'didactic-sequences',
    path: '/didactic-sequences',
    service: 'didactic-sequences',
  },
  {
    name: 'teachers',
    path: '/teachers',
    service: 'teachers',
    query: { monitor: false },
  },
  {
    name: 'zoomTeachers',
    path: '/teachers',
    service: 'teachers',
    query: { monitor: false, own_base: true },
  },
  {
    name: 'schoolTeachers',
    path: '/teachers',
    service: 'teachers',
    query: { monitor: false, own_base: false },
  },
  {
    name: 'monitors',
    path: '/teachers',
    service: 'teachers',
    query: { monitor: true },
  },
  {
    name: 'zoomMonitors',
    path: '/teachers',
    service: 'teachers',
    query: { monitor: true, own_base: true },
  },
  {
    name: 'states',
    path: '/states',
    service: 'states',
  },
  {
    name: 'microsoft-rooms',
    path: '/microsoft-rooms',
    service: 'microsoft-rooms',
  },
  {
    name: 'shorters',
    path: '/shorters',
    service: 'shorters',
  },
  {
    name: 'users',
    path: '/users',
    service: 'users',
  },
  {
    name: 'classrooms',
    path: '/classrooms',
    service: 'classrooms',
  },
  {
    name: 'future-works',
    path: '/future-works',
    service: 'future-works',
  },
  {
    name: 'activities',
    path: '/jornadaz/activities',
    service: 'jornadaz/activities',
  },
  {
    name: 'students',
    path: '/students',
    service: 'students',
  },
  {
    name: 'responsibles',
    path: '/responsibles',
    service: 'responsibles',
  },
  {
    name: 'grades',
    path: '/grades',
    service: 'grades',
  },
  {
    name: 'coordinators',
    path: '/coordinators',
    service: 'coordinators',
  },
  {
    name: 'directors',
    path: '/directors',
    service: 'directors',
  },
  {
    name: 'franchisee-adms',
    path: '/franchisee-adms',
    service: 'franchisee-adms',
  },
  {
    name: 'tracks',
    path: '/tracks',
    service: 'tracks',
  },
  {
    name: 'programs',
    path: '/programs',
    service: 'programs',
  },
  {
    name: 'news',
    path: '/news',
    service: 'news',
  },
  {
    name: 'family-chats',
    path: '/family-chats',
    service: 'family-chats',
  },
  {
    name: 'teaching-segments',
    path: '/teaching-segments',
    service: 'teaching-segments',
  },
  {
    name: 'franchisee-coordinators',
    path: '/franchisee-coordinators',
    service: 'franchisee-coordinators',
  },
  {
    name: 'permissions',
    path: '/permissions',
    service: 'permissions',
  },
];
