import React, { Suspense, useState, useEffect } from 'react';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import store from '../../store';
import ProtectedRoute from '../../components/ProtectedRoute';
import canDo from '../../canDo';

import AppLayout from '../../layout/AppLayout';

const Dashboard = React.lazy(() =>
  import(/* webpackChunkName: "viwes-dashboard" */ './dashboard')
);
const ChangePassword = React.lazy(() =>
  import(/* webpackChunkName: "viwes-dashboard" */ './change-password')
);
const MyAccount = React.lazy(() =>
  import(/* webpackChunkName: "viwes-dashboard" */ './my-account')
);
const Contributors = React.lazy(() =>
  import(/* webpackChunkName: "viwes-second-menu" */ './contributors')
);
const Franchisees = React.lazy(() =>
  import(/* webpackChunkName: "viwes-second-menu" */ './franchisees')
);
const Schools = React.lazy(() =>
  import(/* webpackChunkName: "viwes-second-menu" */ './schools')
);
const Advisors = React.lazy(() =>
  import(/* webpackChunkName: "viwes-second-menu" */ './advisors')
);
const Teachers = React.lazy(() =>
  import(/* webpackChunkName: "viwes-second-menu" */ './teachers')
);
const Monitors = React.lazy(() =>
  import(/* webpackChunkName: "viwes-second-menu" */ './monitors')
);
const Students = React.lazy(() =>
  import(/* webpackChunkName: "viwes-second-menu" */ './students')
);
const Responsibles = React.lazy(() =>
  import(/* webpackChunkName: "viwes-second-menu" */ './responsibles')
);
const Materials = React.lazy(() =>
  import(/* webpackChunkName: "viwes-second-menu" */ './materials')
);
const Activities = React.lazy(() =>
  import(/* webpackChunkName: "viwes-second-menu" */ './activities')
);
const Jornadaz = React.lazy(() =>
  import(/* webpackChunkName: "viwes-second-menu" */ './jornadaz')
);
const MicrosoftRooms = React.lazy(() =>
  import(/* webpackChunkName: "viwes-second-menu" */ './microsoft-rooms')
);
const Shorters = React.lazy(() =>
  import(/* webpackChunkName: "viwes-second-menu" */ './shorters')
);
const News = React.lazy(() =>
  import(/* webpackChunkName: "viwes-second-menu" */ './news')
);
const FamilyChats = React.lazy(() =>
  import(/* webpackChunkName: "viwes-second-menu" */ './family-chats')
);

const AdmPermissions = React.lazy(() =>
  import(/* webpackChunkName: "viwes-second-menu" */ './admPermissions')
);

const BlankPage = React.lazy(() =>
  import(/* webpackChunkName: "viwes-blank-page" */ './blank-page')
);

const App = (props) => {
  const [loading, setLoading] = useState(true);

  async function fetchStates() {
    console.log('fetching states...');
    await store.dispatch({
      type: 'lists/GET_RESOURCE_LIST_REQUEST',
      payload: { name: 'states', perPage: -1, sort: { acronym: 1 } },
      onComplete: ({ error, cancelled, data }) => {
        if (error) {
          console.log(error, cancelled, data);
        } else {
          console.log(error, cancelled, data);
          store.dispatch({
            type: 'states/SET_STATE_REQUEST',
            payload: {
              state: data,
            },
            onComplete: ({ error, cancelled, data }) => {
              console.log('terminou');
              if (error) {
                console.log(error, cancelled, data);
              } else {
                console.log(error, cancelled, data);
              }
            },
          });
        }
        setLoading(false);
      },
    });
  }
  useEffect(() => {
    fetchStates();
  }, []);

  const { match } = props;

  if (loading) {
    return <div className="loading"></div>;
  }

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Suspense fallback={<div className="loading" />}>
          <Switch>
            <Redirect
              exact
              from={`${match.url}/`}
              to={`${match.url}/dashboard`}
            />

            <Route
              path={`/app/minha-conta`}
              render={(props) => <MyAccount {...props} />}
            />
            <Route
              path={`/app/alterar-senha`}
              render={(props) => <ChangePassword {...props} />}
            />

            <ProtectedRoute
              path={`${match.url}/contributors`}
              render={(props) => <Contributors {...props} />}
              requiredPermissions={canDo('contributors', 'list')}
            />
            <ProtectedRoute
              path={`${match.url}/dashboard`}
              render={(props) => <Dashboard {...props} />}
              requiredPermissions={canDo('dashboard', 'list')}
            />

            <ProtectedRoute
              path={`${match.url}/franquias`}
              render={(props) => <Franchisees {...props} />}
              requiredPermissions={canDo('franchisees', 'list')}
            />

            {/* Rotas de franquia */}
            <ProtectedRoute
              path={`${match.url}/franquias`}
              render={(props) => <Franchisees {...props} />}
              requiredPermissions={canDo('franchisees', 'list')}
            />
            {/* Fim rotas de franquia */}
            {/* Rotas de escola */}
            <ProtectedRoute
              path={`${match.url}/escolas`}
              render={(props) => <Schools {...props} />}
              requiredPermissions={canDo('schools', 'list')}
            />
            <ProtectedRoute
              path={`${match.url}/orientadores`}
              render={(props) => <Advisors {...props} />}
              requiredPermissions={canDo('schools', 'list')}
            />
            {/* Fim rotas de escola */}

            <ProtectedRoute
              path={`${match.url}/professores`}
              render={(props) => <Teachers {...props} />}
              requiredPermissions={canDo('teachers', 'list')}
            />
            <ProtectedRoute
              path={`${match.url}/monitores`}
              render={(props) => <Monitors {...props} />}
              requiredPermissions={canDo('teachers', 'list')}
            />
            <ProtectedRoute
              path={`${match.url}/alunos`}
              render={(props) => <Students {...props} />}
              requiredPermissions={canDo('students', 'list')}
            />
            <ProtectedRoute
              path={`${match.url}/responsaveis`}
              render={(props) => <Responsibles {...props} />}
              requiredPermissions={canDo('teachers', 'list')}
            />
            <ProtectedRoute
              path={`${match.url}/noticias`}
              render={(props) => <News {...props} />}
              requiredPermissions={canDo('news', 'list')}
            />
            <ProtectedRoute
              path={`${match.url}/batepapo`}
              render={(props) => <FamilyChats {...props} />}
              requiredPermissions={canDo('family-chats', 'list')}
            />
            <ProtectedRoute
              path={`${match.url}/materiais`}
              render={(props) => <Materials {...props} />}
              requiredPermissions={canDo('materials', 'list')}
            />
            <ProtectedRoute
              path={`${match.url}/atividades`}
              render={(props) => <Activities {...props} />}
              requiredPermissions={canDo('activities', 'list')}
            />
            <ProtectedRoute
              path={`${match.url}/jornadas`}
              render={(props) => <Jornadaz {...props} />}
              requiredPermissions={canDo('jornadaz', 'list')}
              permissionGroup={true}
            />
            <ProtectedRoute
              path={`${match.url}/microsoft-rooms`}
              render={(props) => <MicrosoftRooms {...props} />}
              requiredPermissions={canDo('microsoft-rooms', 'list')}
            />
            <ProtectedRoute
              path={`${match.url}/encurtador`}
              render={(props) => <Shorters {...props} />}
              requiredPermissions={canDo('shorters', 'list')}
              permissionGroup={true}
            />
            <ProtectedRoute
              path={`${match.url}/adm-permissions`}
              render={(props) => <AdmPermissions {...props} />}
              requiredPermissions={canDo('shorters', 'list')}
              permissionGroup={true}
            />
            <ProtectedRoute
              path={`${match.url}/blank-page`}
              render={(props) => <BlankPage {...props} />}
              requiredPermissions={canDo('materials', 'list')}
            />
          </Switch>
        </Suspense>
      </div>
    </AppLayout>
  );
};
const mapStateToProps = (state) => {
  const { containerClassnames } = state.menu;
  return {
    containerClassnames,
    authentication: state.authentication,
    states: state.states,
  };
};

export default withRouter(connect(mapStateToProps, {})(App));
