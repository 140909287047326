export default [
  {
    url: '/app/adm-permissions/list',
    menu: 'admPermissions',
    submenu: null,
    form: null,
  },
  {
    url: '/app/adm-permissions/form',
    menu: 'admPermissions',
    submenu: 'null',
    form: 'default',
  },
];
