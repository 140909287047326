import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import { Button, Card, FormGroup, Label, Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import store from '../../store';
import validationSchema from './changeForgotSchema';

const ChangeForgotPassword = (props) => {
  const query = new URLSearchParams(props.location.search);

  function handleSubmit(values) {
    store.dispatch({
      type: 'forgot_password/CHANGE_PASSWORD_REQUEST',
      payload: {
        newPassword: values.password,
        token: query.get('token'),
      },
      onComplete: ({ error, cancelled, data }) => {
        if (error) {
          console.log(error, cancelled, data);
        } else {
          console.log(error, cancelled, data);
        }
      },
    });
  }

  function navigateToLogin() {
    setTimeout(() => {
      props.history.push('/login');
    }, 5000);
  }

  // const validatePassword = () => {
  //   console.log('values');
  //   let error;
  //   if (!password) {
  //     error = 'Por favor insira sua senha';
  //   } else if (password !== confirmedPassword) {
  //     error = 'As senhas não são iguais';
  //   } else if (password.length < 6) {
  //     error = 'A senha precisa ser de no minimo 6 caracteres';
  //   }
  //   return error;
  // };

  if (props.authentication.token != null) {
    return <Redirect to={'/app'} />;
  }

  if (!query.get('token')) {
    return <Redirect to={'/esqueci-minha-senha'} />;
  }

  return (
    <main>
      <Row className="h-100">
        <Colxx xxs="12" md="10" className="mx-auto my-auto">
          <Card className="auth-card">
            <div className="position-relative image-side "></div>
            <div className="form-side">
              <p className="logo-single" />
              {props.forgotPassword.error ? (
                <p
                  style={{
                    fontSize: '14px',
                    textAlign: 'start',
                    paddingTop: '15px',
                  }}
                >
                  Ocorreu um erro ao alterar sua senha, para realizar uma nova
                  solicitação clique{' '}
                  <a style={{ fontWeight: 'bold' }} href="/esqueci-minha-senha">
                    aqui
                  </a>
                </p>
              ) : props.forgotPassword.passwordChanged ? (
                <p
                  style={{
                    fontSize: '14px',
                    textAlign: 'start',
                    paddingTop: '15px',
                  }}
                >
                  Senha alterada com sucesso, você será redirecionado para tela
                  de login em instantes.
                  {navigateToLogin()}
                </p>
              ) : (
                <Formik
                  validationSchema={validationSchema({
                    password: '',
                    confirmedPassword: '',
                  })}
                  onSubmit={handleSubmit}
                  initialValues={{ password: '', confirmedPassword: '' }}
                >
                  {({ errors, touched, values }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <p
                        style={{
                          fontSize: '14px',
                          textAlign: 'start',
                          paddingBottom: '15px',
                        }}
                      >
                        Escolha uma nova senha e a confirme no campo seguinte.
                      </p>
                      <FormGroup className="form-group has-float-label">
                        <Label>Senha </Label>
                        <Field
                          className="form-control"
                          type="password"
                          name="password"
                          // validate={validatePassword}
                          value={values.password}
                        />
                        {touched.password && errors.password && (
                          <div className="invalid-feedback d-block">
                            {errors.password}
                          </div>
                        )}
                      </FormGroup>
                      <FormGroup className="form-group has-float-label">
                        <Label>Confirmar Senha </Label>
                        <Field
                          className="form-control"
                          type="password"
                          name="confirmedPassword"
                          // validate={validatePassword}
                          value={values.confirmedPassword}
                        />
                        {touched.confirmedPassword &&
                          errors.confirmedPassword && (
                            <div className="invalid-feedback d-block">
                              {errors.confirmedPassword}
                            </div>
                          )}
                      </FormGroup>
                      <div className="d-flex justify-content-between align-items-center">
                        <NavLink to={`/Login`}>Login</NavLink>
                        <Button
                          color="primary"
                          className={`btn-shadow btn-multiple-state ${
                            props.authentication.loading ? 'show-spinner' : ''
                          }`}
                          size="lg"
                          type="submit"
                          style={{
                            width: '163px',
                            height: '40px',
                            fontSize: '14px',
                          }}
                        >
                          <span className="spinner d-inline-block">
                            <span className="bounce1" />
                            <span className="bounce2" />
                            <span className="bounce3" />
                          </span>
                          <p className="label">ENVIAR</p>
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </Card>
        </Colxx>
      </Row>
    </main>
  );
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    forgotPassword: state.forgotPassword,
  };
};
export default connect(mapStateToProps)(ChangeForgotPassword);
