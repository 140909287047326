export const Types = {
  CHANGE_CLASS: 'dynamicClasses/CHANGE_CLASS',
};

export default function reducer(
  state = {
    main: 'menu-default',
    container: 'menu-default',
  },
  action
) {
  switch (action.type) {
    case Types.CHANGE_CLASS:
      console.log('payload action', action);
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
