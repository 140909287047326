const canDo = (label, action) => {};
let main = {
  main: [
    {
      icon: 'iconsminds-building',
      label: 'Dados',
      permission: canDo('schools', 'list'),
      to: `/app/escolas/escola/:id/dados`,
      custom: true,
    },
    {
      icon: 'iconsminds-building',
      label: 'Endereço de cobrança',
      permission: canDo('schools', 'list'),
      to: `/app/escolas/escola/:id/endereco/cobranca`,
      custom: true,
    },
    {
      icon: 'iconsminds-building',
      label: 'Endereço de entrega',
      permission: canDo('schools', 'list'),
      to: `/app/escolas/escola/:id/endereco/entrega`,
      custom: true,
    },
    {
      icon: 'iconsminds-building',
      label: 'Orientadores Zoom',
      permission: canDo('advisors', 'list'),
      to: `/app/escolas/escola/:id/orientadores/lista`,
      custom: true,
    },
    {
      icon: 'iconsminds-building',
      label: 'Monitores Zoom',
      permission: canDo('teachers', 'list'),
      to: `/app/escolas/escola/:id/monitores/lista`,
      custom: true,
    },
    {
      icon: 'iconsminds-building',
      label: 'Turmas',
      permission: canDo('classrooms', 'list'),
      to: `/app/escolas/escola/:id/turmas/lista`,
      custom: true,
    },
    {
      icon: 'iconsminds-building',
      label: 'Cronogramas',
      permission: canDo('future-works', 'list'),
      to: `/app/escolas/escola/:id/cronogramas/lista`,
      custom: true,
    },
    {
      icon: 'iconsminds-building',
      label: 'Atividades',
      permission: canDo('schools', 'list'),
      to: `/app/escolas/escola/:id/atividades/lista`,
      custom: true,
    },
    {
      icon: 'iconsminds-building',
      label: 'Alunos',
      permission: canDo('schools', 'list'),
      to: `/app/escolas/escola/:id/alunos/lista`,
      custom: true,
    },
    {
      icon: 'iconsminds-building',
      label: 'Coordenadores',
      permission: canDo('schools', 'list'),
      to: `/app/escolas/escola/:id/coordenadores/lista`,
      custom: true,
    },
    {
      icon: 'iconsminds-building',
      label: 'Professores',
      permission: canDo('teachers', 'list'),
      to: `/app/escolas/escola/:id/professores/lista`,
      custom: true,
    },
    {
      icon: 'iconsminds-building',
      label: 'Diretores',
      permission: canDo('schools', 'list'),
      to: `/app/escolas/escola/:id/diretores/lista`,
      custom: true,
    },
  ],
};

export default main;
