import React, { Component } from 'react';
import { WithWizard } from 'react-albus';
import { Button } from 'reactstrap';

export class BottomNavigation extends Component {
  render() {
    return (
      <WithWizard
        render={({ next, previous, step, steps }) => (
          <div className={'wizard-buttons ' + this.props.className}>
            <Button
              color='primary'
              className={'mr-1 ' + (steps.indexOf(step) <= 0 ? 'disabled' : '')}
              onClick={() => {
                this.props.onClickPrev(previous, steps, step);
              }}
            >
              {this.props.prevLabel}
            </Button>

            {steps.indexOf(step) >= steps.length - 1 ? (
              <Button
                color='primary'
                type={'submit'}
                onClick={() => {
                  this.props.onSubmit();
                }}
              >
                Salvar
              </Button>
            ) : (
              <Button
                color='primary'
                className={
                  steps.indexOf(step) >= steps.length - 1 ? 'disabled' : ''
                }
                onClick={() => {
                  this.props.onClickNext(next, steps, step);
                }}
              >
                {this.props.nextLabel}
              </Button>
            )}
          </div>
        )}
      />
    );
  }
}
