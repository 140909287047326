import * as yup from 'yup';
import checkEmail from '../../functions/validates/checkEmail';

const getSchema = (resource) => {
  let schema = yup.object().shape({
    password: yup.string().min(6, 'Senha tem que ter pelo menos 6 caracteres.'),
    confirmedPassword: yup
      .string()
      .oneOf(
        [yup.ref('password'), null],
        'Senha e confirmar senha tem que ser iguais.'
      ),
  });
  return schema;
};

export default getSchema;
