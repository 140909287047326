export default [
  {
    url: '/app/batepapo/main',
    menu: 'familyChats',
    submenu: null,
    form: null,
  },
  {
    url: '/app/batepapo/form',
    menu: 'familyChats',
    submenu: null,
    form: 'familyChats',
  },
];
