import React, { useEffect } from 'react';
import TopNav from '../containers/navs/Topnav';
import Sidebar from '../containers/navs/Sidebar';
import Footer from '../containers/navs/Footer';
import { useSelector } from 'react-redux';

const AppLayout = (props) => {
  const dynamicClasses = useSelector((state) => state.dynamicClasses);
  const menu = useSelector((state) => state.menu);

  useEffect(() => {
    console.log('menu123', menu);
  }, [menu]);

  return (
    <div
      id="app-container"
      className={
        menu.submenuOpened == true ? 'menu-default' : 'main-show-temporary'
      }
    >
      <TopNav history={props.history} />
      <Sidebar />
      <main
        className={menu.submenuOpened == true ? 'menu-default' : 'sub-hidden'}
      >
        <div className="container-fluid">{props.children}</div>
      </main>
      <Footer />
    </div>
  );
};
export default AppLayout;
