export default [
  {
    url: '/app/contributors/contributors/list',
    menu: 'zoommers',
    submenu: 'todos',
    form: null,
  },
  {
    url: '/app/contributors/admins/list',
    menu: 'zoommers',
    submenu: 'administradores',
    form: null,
  },
  {
    url: '/app/contributors/admins/form',
    menu: 'zoommers',
    submenu: 'administradores',
    form: 'admins',
  },
  {
    url: '/app/contributors/administratives/list',
    menu: 'zoommers',
    submenu: 'administratives',
    form: null,
  },
  {
    url: '/app/contributors/administratives/form',
    menu: 'zoommers',
    submenu: 'administratives',
    form: 'administratives',
  },
  {
    url: '/app/contributors/sellers/list',
    menu: 'zoommers',
    submenu: 'sellers',
    form: null,
  },
  {
    url: '/app/contributors/sellers/form',
    menu: 'zoommers',
    submenu: 'sellers',
    form: 'sellers',
  },
  {
    url: '/app/contributors/developments/list',
    menu: 'zoommers',
    submenu: 'developments',
    form: null,
  },
  {
    url: '/app/contributors/developments/form',
    menu: 'zoommers',
    submenu: 'developments',
    form: 'developments',
  },
  {
    url: '/app/contributors/implementations/list',
    menu: 'zoommers',
    submenu: 'implementations',
    form: null,
  },
  {
    url: '/app/contributors/implementations/form',
    menu: 'zoommers',
    submenu: 'implementations',
    form: 'implementations',
  },
  {
    url: '/app/contributors/marketings/list',
    menu: 'zoommers',
    submenu: 'marketings',
    form: null,
  },
  {
    url: '/app/contributors/marketings/form',
    menu: 'zoommers',
    submenu: 'marketings',
    form: 'marketings',
  },
  {
    url: '/app/orientadores/zoom',
    menu: 'zoommers',
    submenu: 'advisors',
    form: null,
  },
  {
    url: '/app/orientadores/zoom/novo',
    menu: 'zoommers',
    submenu: 'advisors',
    form: 'zoomAdvisors',
  },
  {
    url: '/app/orientadores/zoom/editar',
    menu: 'zoommers',
    submenu: 'advisors',
    form: 'zoomAdvisors',
  },
  {
    url: '/app/professores/zoom',
    menu: 'zoommers',
    submenu: 'teachers',
    form: null,
  },
  {
    url: '/app/professores/zoom/novo',
    menu: 'zoommers',
    submenu: 'teachers',
    form: 'zoomTeachers',
  },
  {
    url: '/app/professores/zoom/editar',
    menu: 'zoommers',
    submenu: 'teachers',
    form: 'zoomTeachers',
  },
  {
    url: '/app/monitores/zoom',
    menu: 'zoommers',
    submenu: 'monitors',
    form: null,
  },
  {
    url: '/app/monitores/zoom/novo',
    menu: 'zoommers',
    submenu: 'monitors',
    form: 'zoomTeachers',
  },
  {
    url: '/app/monitores/zoom/editar',
    menu: 'zoommers',
    submenu: 'monitors',
    form: 'zoomTeachers',
  },
];
