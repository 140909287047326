import getOneResource from './getOneResource';

export default async (franchiseeId) => {
  let _ownBase = false;
  if (franchiseeId !== null && franchiseeId !== undefined) {
    let franchiseeResponse = await getOneResource(franchiseeId, 'franchisees');
    if (franchiseeResponse.error == undefined) {
      _ownBase = franchiseeResponse.own_base;
    }
  }
  return _ownBase;
};
