export const Types = {
  SUB_MENU_OPEN: 'menu/SUB_MENU_OPEN',
  SUB_MENU_CLOSE: 'menu/SUB_MENU_CLOSE',
  SET_SUB_MENU_SHOW: 'menu/SET_SUB_MENU_SHOW',
};

export default function reducer(
  state = {
    submenuOpened: false,
    submenuShow: [],
  },
  action
) {
  switch (action.type) {
    case Types.SUB_MENU_OPEN:
      return { ...state, submenuOpened: true };
    case Types.SUB_MENU_CLOSE:
      return { ...state, submenuOpened: false };
    case Types.SET_SUB_MENU_SHOW:
      console.log('action', action);
      return { ...state, submenuShow: action.payload.submenuShow };
    default:
      return state;
  }
}
