import { call, put } from 'redux-saga/effects';
import listInfos from '../listInfos';
import feathers from '../../feathers';

const _create = async (_resource, payload) => {
  return feathers
    .service(_resource.service)
    .create(payload.data)
    .then((response) => {
      console.log('response', response);
      return response;
    });
};

export function* create({ payload }) {
  const _resource = listInfos.find(
    (listInfos) => listInfos.name == payload.name
  );
  if (_resource) {
    const resource = yield call(_create, _resource, payload);
    yield put({ type: 'create/RESOURCE_CREATE_SUCCESS' });
    return resource;
  } else {
    //   error resource not found
    yield put({
      type: 'create/POST_RESOURCE_CREATE_ERROR',
      error: 'not found',
    });
  }
}
