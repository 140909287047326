import feathers from '../feathers';
import listInfos from '../store/listInfos';

export default async (id, resourceName) => {
  let info = listInfos.find((listInfo) => listInfo.name == resourceName);
  if (info) {
    return feathers
      .service(info.service)
      .get(id)
      .then((response) => response)
      .catch((err) => {
        console.log('err', err);
        return { error: 'error to get info' };
      });
  } else {
    return { error: 'Resource not found' };
  }
};
