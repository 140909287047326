import * as yup from 'yup';
import checkEmail from '../../functions/validates/checkEmail';

const getSchema = (resource) => {
  let schema = yup.object().shape({
    email: yup
      .string()
      .nullable()
      .email('Email inválido.')
      .test('EmailDuplicate', 'Email não cadastrado', async (value) => {
        let checkedEmail = await checkEmail(value, resource.email);
        return !checkedEmail;
      }),
  });
  return schema;
};

export default getSchema;
