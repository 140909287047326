import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import { Button, Card, FormGroup, Label, Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import store from '../../store';
import Wizard from '../../components/ui/forms/wizard';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { authProvider } from '../../constants/msConfig';
import * as yup from 'yup';
import checkSpecialEmail from '../../functions/validates/checkSpecialEmail';

const Login = (props) => {
  const dispatch = useDispatch();
  let isAuthenticated = false;
  let loginMs = null;
  const [requestedLoginMs, setRequestedLoginMs] = useState(false);
  const [microsoftError, setMicrosoftError] = useState(null);
  const [openedEmail, setOpenedEmail] = useState(false);

  useEffect(() => {
    if (requestedLoginMs == true) {
      setMicrosoftError(null);
      if (isAuthenticated == true) {
        authProvider.getAccessToken().then((token) => {
          dispatch({
            type: 'authentication/SIGN_IN_REQUEST',
            payload: {
              email: null,
              password: null,
              microsoftToken: token.accessToken,
            },
            onComplete: ({ error, cancelled, data }) => {
              if (data == false) {
                setMicrosoftError(true);
                setRequestedLoginMs(false);
              }
            },
          });
        });
      } else {
        loginMicrosoft();
      }
    }
  }, [requestedLoginMs]);

  const validationSchema = () => {
    let schema = yup.object().shape({
      password: yup
        .string()
        .nullable()
        .required('Senha é obrigatório.')
        .min(6, 'Senha tem que ter pelo menos 6 caracteres.'),
      email: yup
        .string()
        .trim('Email não pode ter espaços em branco.')
        .nullable()
        .email('Email inválido.')
        .test(
          'EmailSpecialCharacters',
          'Email não pode ter caracteres especiais',
          (value) => checkSpecialEmail(value)
        ),
    });
    return schema;
  };

  const loginMicrosoft = async () => {
    let result = await loginMs();
    alert('login');
    alert(result);
  };

  function handleSubmit({ email, password }) {
    store.dispatch({
      type: 'authentication/SIGN_IN_REQUEST',
      payload: {
        email,
        password,
      },
      onComplete: ({ error, cancelled, data }) => {
        if (error) {
          console.log(error, cancelled, data);
        } else {
          console.log(error, cancelled, data);
        }
      },
    });
  }

  const validateEmail = (value) => {
    let error;
    if (!value === '') {
      error = 'Por favor insira seu e-mail';
    }
    return error;
  };

  const validatePassword = (value) => {
    let error;
    console.log(value);
    if (!value === '') {
      error = 'Por favor insira sua senha';
    } else if (value.length < 6) {
      error = 'senha muito pequena';
    }
    return error;
  };

  if (props.authentication.token != null) {
    return <Redirect to={'/app'} />;
  }
  return (
    <main>
      <Row className="h-100">
        <Colxx xxs="12" md="10" className="mx-auto my-auto">
          <Card className="auth-card">
            <div className="position-relative image-side "></div>
            <div className="form-side">
              <p className="logo-single" />
              <AzureAD provider={authProvider}>
                {({ login, logout, authenticationState }) => {
                  loginMs = login;
                  const isInProgress =
                    authenticationState === AuthenticationState.InProgress;
                  isAuthenticated =
                    authenticationState === AuthenticationState.Authenticated;
                  const isUnauthenticated =
                    authenticationState === AuthenticationState.Unauthenticated;

                  // if (isAuthenticated) {
                  //   return <div>logado</div>;
                  // } else {
                  return (
                    <>
                      {openedEmail == true && (
                        <Formik
                          onSubmit={handleSubmit}
                          initialValues={{ email: '', password: '' }}
                          validationSchema={validationSchema()}
                        >
                          {({ errors, touched, values, handleChange }) => (
                            <Form className="av-tooltip tooltip-label-bottom">
                              <FormGroup className="form-group has-float-label">
                                <Label>E-mail </Label>
                                <Field
                                  className="form-control"
                                  name="email"
                                  // validate={validateEmail}
                                  value={values.email}
                                  onChange={handleChange}
                                />
                              </FormGroup>
                              <FormGroup className="form-group has-float-label">
                                <Label>Senha </Label>
                                <Field
                                  className="form-control"
                                  type="password"
                                  name="password"
                                  // validate={validatePassword}
                                  value={values.password}
                                  onChange={handleChange}
                                />
                              </FormGroup>
                              {touched.email && errors.email && (
                                <p className="error-label">{errors.email}</p>
                              )}
                              {touched.password && errors.password && (
                                <p className="error-label">{errors.email}</p>
                              )}

                              {props.authentication.error &&
                              !props.authentication.loading &&
                              microsoftError == null ? (
                                <p className={'error-label'}>
                                  Email e/ou senha inválido.
                                </p>
                              ) : microsoftError != null ? (
                                <p className={'error-label'}>
                                  Erro ao se conectar tente novamente.
                                </p>
                              ) : (
                                <p style={{ height: 20 }}></p>
                              )}

                              <div className="d-flex justify-content-between align-items-center">
                                <NavLink to={`/esqueci-minha-senha`}>
                                  Esqueci minha senha
                                </NavLink>
                                <Button
                                  color="primary"
                                  className={`btn-shadow btn-multiple-state ${
                                    props.authentication.loading
                                      ? 'show-spinner'
                                      : ''
                                  }`}
                                  size="lg"
                                  type="submit"
                                  style={{
                                    width: '163px',
                                    height: '40px',
                                    fontSize: '14px',
                                  }}
                                >
                                  <span className="spinner d-inline-block">
                                    <span className="bounce1" />
                                    <span className="bounce2" />
                                    <span className="bounce3" />
                                  </span>
                                  <p
                                    className="label"
                                    style={{ marginBottom: 0 }}
                                  >
                                    ENTRAR
                                  </p>
                                </Button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      )}
                      <br />
                      <br />
                      <Button
                        className={`btn-shadow btn-multiple-state ${
                          props.authentication.loading ? 'show-spinner' : ''
                        }`}
                        onClick={() => {
                          setRequestedLoginMs(true);
                          // login();
                        }}
                        disabled={isInProgress}
                        size="lg"
                      >
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                        <p className="label" style={{ marginBottom: 0 }}>
                          {' '}
                          Entrar com microsoft
                        </p>
                      </Button>
                      {openedEmail == false && (
                        <>
                          <br />
                          <br />
                          <Button
                            className={`btn-shadow btn-multiple-state ${
                              props.authentication.loading ? 'show-spinner' : ''
                            }`}
                            onClick={() => {
                              setOpenedEmail(true);
                              // login();
                            }}
                            disabled={isInProgress}
                            size="lg"
                          >
                            <span className="spinner d-inline-block">
                              <span className="bounce1" />
                              <span className="bounce2" />
                              <span className="bounce3" />
                            </span>
                            <p className="label" style={{ marginBottom: 0 }}>
                              {' '}
                              Entrar com email e senha
                            </p>
                          </Button>
                        </>
                      )}
                    </>
                  );
                  // }
                }}
              </AzureAD>
            </div>
          </Card>
        </Colxx>
      </Row>
    </main>
  );
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
  };
};
export default connect(mapStateToProps)(Login);
