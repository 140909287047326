export const Types = {
  GET_RESOURCE_LIST_REQUEST: 'lists/GET_RESOURCE_LIST_REQUEST',
  GET_RESOURCE_LIST_SUCCESS: 'lists/GET_RESOURCE_LIST_SUCCESS',
  GET_RESOURCE_LIST_ERROR: 'lists/GET_RESOURCE_LIST_ERROR'
};

export default function reducer(
  state = {
    loading: false,
    error: null
  },
  action
) {
  switch (action.type) {
    case Types.GET_RESOURCE_LIST_REQUEST:
      return { ...state, loading: true };
    case Types.GET_RESOURCE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case Types.GET_RESOURCE_LIST_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
}
