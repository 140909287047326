import getOneResource from './getOneResource';
import store from '../store';

// let permissions = [
//   {
//     name: 'zoommers',
//     groupsCanView: ['admins'],
//     usersCanView: [],
//     submenus: [
//       {
//         name: 'todos',
//         groupsCanView: ['admins'],
//         usersCanView: [],
//         groupsCanEdit: ['admins'],
//         usersCanEdit: [],
//         groupsCanCreate: [],
//         usersCanCreate: [],
//       },
//       {
//         name: 'administradores',
//         groupsCanView: ['admins'],
//         usersCanView: [],
//         groupsCanEdit: ['admins'],
//         usersCanEdit: [],
//         groupsCanCreate: [],
//         usersCanCreate: [],
//         forms: [
//           {
//             name: 'default',
//             fields: [
//               {
//                 name: 'name',
//                 groupsCanView: ['admins'],
//                 usersCanView: [],
//                 groupCanEdit: ['admins'],
//                 usersCanEdit: [],
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     name: 'admPermissions',
//     entitiesCanView: ['admins'],
//     groupsCanView: ['admins'],
//     usersCanView: [],
//     submenus: [],
//     forms: [
//       {
//         name: 'default',
//         entitiesCanView: ['admins'],
//         groupsCanView: ['admins'],
//         usersCanView: [],
//         entitiesCanEdit: ['admins'],
//         groupsCanEdit: [],
//         usersCanEdit: [],
//         entitiesCanCreate: ['admins'],
//         groupsCanCreate: [],
//         usersCanCreate: [],
//         fields: [
//           {
//             name: 'name',
//             entitiesCanView: ['admins'],
//             groupsCanView: ['admins'],
//             usersCanView: [],
//             entitiesCanEdit: ['admins'],
//             groupsCanEdit: [],
//             usersCanEdit: [],
//           },
//           {
//             name: 'submenus.name',
//             entitiesCanView: ['admins'],
//             groupsCanView: ['admins'],
//             usersCanView: [],
//             entitiesCanEdit: ['admins'],
//             groupsCanEdit: [],
//             usersCanEdit: [],
//           },
//           {
//             name: 'forms.name',
//             entitiesCanView: ['admins'],
//             groupsCanView: ['admins'],
//             usersCanView: [],
//             entitiesCanEdit: ['admins'],
//             groupsCanEdit: [],
//             usersCanEdit: [],
//           },
//           {
//             name: 'forms.fields.name',
//             entitiesCanView: ['admins'],
//             groupsCanView: ['admins'],
//             usersCanView: [],
//             entitiesCanEdit: ['admins'],
//             groupsCanEdit: [],
//             usersCanEdit: [],
//           },
//         ],
//       },
//     ],
//   },
// ];

export default () => {
  let userPermission = { view: false, edit: false, create: false, form: null };
  const permissions = store.getState().permissions;
  const permission = store.getState().authentication.permission;
  const user = store.getState().authentication.user;
  const trackUser = store.getState().trackUser;
  console.log('user', user);
  console.log('trackUser', trackUser);
  // const menuPermission = true
  const menuPermission = permissions.find(
    // (_permission) => _permission.name == true
    (_permission) => _permission.name == trackUser.menu
  );
  if (menuPermission) {
    if (
      menuPermission.entitiesCanView.includes(permission) ||
      menuPermission.groupsCanView.includes(permission) ||
      menuPermission.usersCanView.includes(user._id)
    ) {
      if (trackUser.submenu == null && trackUser.form == null) {
        console.log('sem submenu ou form');
        return true;
      }
      const subMenuPermission = menuPermission.submenus.find(
        (_submenu) => _submenu.name == trackUser.submenu
      );
      if (subMenuPermission) {
        console.log('subMenuPermission', subMenuPermission);
        userPermission = {
          view:
            subMenuPermission.entitiesCanView.includes(permission) ||
            subMenuPermission.groupsCanView.includes(permission) ||
            subMenuPermission.usersCanView.includes(user._id),
          edit:
            subMenuPermission.entitiesCanEdit.includes(permission) ||
            subMenuPermission.groupsCanEdit.includes(permission) ||
            subMenuPermission.usersCanEdit.includes(user._id),
          create:
            subMenuPermission.entitiesCanCreate.includes(permission) ||
            subMenuPermission.groupsCanCreate.includes(permission) ||
            subMenuPermission.usersCanCreate.includes(user._id),
        };
      }
      const formPermission = menuPermission.forms.find(
        (_form) => _form.name == trackUser.form
      );
      console.log('formPermission', formPermission);
      if (formPermission) {
        userPermission = {
          view:
            formPermission.entitiesCanView.includes(permission) ||
            formPermission.groupsCanView.includes(permission) ||
            formPermission.usersCanView.includes(user._id),
          edit:
            formPermission.entitiesCanEdit.includes(permission) ||
            formPermission.groupsCanEdit.includes(permission) ||
            formPermission.usersCanEdit.includes(user._id),
          create:
            formPermission.entitiesCanCreate.includes(permission) ||
            formPermission.groupsCanCreate.includes(permission) ||
            formPermission.usersCanCreate.includes(user._id),
        };
      }
    } else {
      console.log('não permitido no menu');
    }
  }
  console.log('userPermission', userPermission);
  // unblock permissions
  userPermission = {
    view: true,
    edit: true,
    create: true,
  };
  return userPermission;
};
