export default [
  {
    url: '/app/noticias/main',
    menu: 'news',
    submenu: null,
    form: null,
  },
  {
    url: '/app/noticias/form',
    menu: 'news',
    submenu: null,
    form: 'news',
  },
];
