import { takeLatest, takeEvery, all } from 'redux-saga/effects';
import { withCallback } from 'redux-saga-callback';
import { getList } from './resourceLists';
import { create } from './resourceCreate';
import { update } from './resourceUpdate';
import { remove } from './resourceRemove';
import { checkAuthed, signIn, signOut } from './authentication';
import { askPasswordChange, changePassword } from './forgotPassword';
import { setStateMaterial } from './materials';
import { setStates } from './states';
import { setMenu } from './menu_items';

export default function* rootSaga() {
  yield all([
    takeLatest('authentication/CHECK_AUTHED', withCallback(checkAuthed)),
    takeLatest('authentication/SIGN_IN_REQUEST', withCallback(signIn)),
    takeLatest('authentication/SIGN_OUT_REQUEST', signOut),

    takeEvery('lists/GET_RESOURCE_LIST_REQUEST', withCallback(getList)),
    takeEvery('create/RESOURCE_CREATE_REQUEST', withCallback(create)),
    takeEvery('update/RESOURCE_UPDATE_REQUEST', withCallback(update)),
    takeEvery('delete/RESOURCE_REMOVE_REQUEST', withCallback(remove)),
    takeLatest(
      'forgot_password/RESET_PASSWORD_REQUEST',
      withCallback(askPasswordChange)
    ),
    takeLatest(
      'forgot_password/CHANGE_PASSWORD_REQUEST',
      withCallback(changePassword)
    ),
    takeEvery('materials/SET_STATE_REQUEST', setStateMaterial),
    takeEvery('states/SET_STATE_REQUEST', setStates),
    takeEvery('menu/SET_MENU_REQUEST', setMenu),
  ]);
}
