import feathers from '../../feathers';

export default (email, initialEmail) => {
  return feathers
    .service('users')
    .find({ query: { email: email } })
    .then((response) => {
      console.log('response', response);
      if (initialEmail == email) {
        return true;
      }
      return response.total == 0;
    })
    .catch((err) => {
      return false;
    });
};
