export default [
  {
    url: '/app/responsaveis/main',
    menu: 'responsibles',
    submenu: null,
    form: null,
  },
  {
    url: '/app/responsaveis/form',
    menu: 'responsibles',
    submenu: 'responsibles',
    form: 'responsibles',
  },
  {
    url: '/app/responsaveis/info',
    menu: 'responsibles',
    submenu: 'responsibles',
    form: 'responsibles',
  },
];
