export const Types = {
  SET_TRACK: 'trackUser/SET_TRACK',
};

export default function reducer(
  state = {
    url: null,
    menu: null,
    submenu: null,
    form: null,
  },
  action
) {
  switch (action.type) {
    case Types.SET_TRACK:
      console.log('aqui', action);
      return {
        url: action.payload.url,
        menu: action.payload.menu,
        submenu: action.payload.submenu,
        form: action.payload.form,
      };
    default:
      return state;
  }
}
