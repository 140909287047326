export default [
  {
    url: '/app/materials/main',
    menu: 'materials',
    submenu: 'materials',
    form: null,
  },
  {
    url: '/app/materiais/jornadas/main',
    menu: 'familyChats',
    submenu: 'journeys',
    form: null,
  },
  {
    url: '/app/materiais/trilhas/main',
    menu: 'familyChats',
    submenu: 'tracks',
    form: null,
  },
];
