export default [
  {
    url: '/app/alunos/main',
    menu: 'students',
    submenu: 'students',
    form: null,
  },
  {
    url: '/app/alunos/novo',
    menu: 'students',
    submenu: 'students',
    form: 'students',
  },
  {
    url: '/app/alunos/editar',
    menu: 'students',
    submenu: 'students',
    form: 'students',
  },
  {
    url: '/app/alunos/zoom',
    menu: 'students',
    submenu: 'studentsZoom',
    form: null,
  },
  {
    url: '/app/alunos/escolas',
    menu: 'students',
    submenu: 'studentsSchool',
    form: null,
  },
];
