export default [
  {
    url: '/app/microsoft-rooms/list',
    menu: 'microsoftRooms',
    submenu: 'microsoftRooms',
    form: null,
  },
  {
    url: '/app/microsoft-rooms/form',
    menu: 'microsoftRooms',
    submenu: 'microsoftRooms',
    form: 'microsoftRooms',
  },
  {
    url: '/app/microsoft-rooms/aprovacao',
    menu: 'microsoftRooms',
    submenu: 'approvalList',
    form: null,
  },
  {
    url: '/app/microsoft-rooms/reprovados',
    menu: 'microsoftRooms',
    submenu: 'reprovedList',
    form: null,
  },
];
