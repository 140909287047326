import React, { useState, useEffect } from 'react';
import {
  Route,
  Redirect,
  useHistory,
  withRouter,
  useLocation,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import convertPathinTrack from '../functions/convertPathinTrack';
import checkPermission from '../functions/checkPermission';
import getManyResource from '../functions/getManyResource';

const AuthedRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [authed, setAuthed] = useState(null);

  const setPreviousUrl = () => {
    dispatch({ type: 'routes/SET_URL', payload: location.pathname });
  };

  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    history.listen(async (location, action) => {
      const pathObject = convertPathinTrack();
      console.log('aqui123', pathObject);
      await dispatch({
        type: 'trackUser/SET_TRACK',
        payload: pathObject,
      });
      let permissions = await getManyResource({ $limit: '-1' }, 'permissions');
      console.log('_permissions', permissions);
      await dispatch({
        type: 'permissions/SET_PERMISSIONS',
        payload: permissions,
      });
      checkPermission();
    });
  }, [history]);
  const checkAuth = async () => {
    dispatch({
      type: 'authentication/CHECK_AUTHED',
      onComplete: async ({ error, cancelled, data }) => {
        if (data.error) {
          history.push('/login');
        } else {
          const pathObject = convertPathinTrack();
          console.log('aqui123', pathObject);
          await dispatch({
            type: 'trackUser/SET_TRACK',
            payload: pathObject,
          });
          console.log('aquiii');
          let permissions = await getManyResource(
            { $limit: '-1' },
            'permissions'
          );
          console.log('_permissions', permissions);
          await dispatch({
            type: 'permissions/SET_PERMISSIONS',
            payload: permissions,
          });
          checkPermission();
          setAuthed(true);
        }
      },
    });
  };
  return (
    <>
      {/* {authed == null && <div className="loading"></div>} */}
      {authed !== null && (
        <Route
          {...rest}
          render={(props) => {
            // setPreviousUrl();
            return <Component {...props} />;
          }}
        />
      )}
    </>
  );
};

export default withRouter(AuthedRoute);
