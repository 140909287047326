import { call, put } from 'redux-saga/effects';
import listInfos from '../listInfos';
import feathers from '../../feathers';

const _remove = async (_resource, payload) => {
  return feathers
    .service(_resource.service)
    .remove(payload.id)
    .then((response) => {
      console.log('response', response);
      return response;
    });
};

export function* remove({ payload }) {
  const _resource = listInfos.find(
    (listInfos) => listInfos.name == payload.name
  );
  if (_resource) {
    const resource = yield call(_remove, _resource, payload);
    yield put({ type: 'delete/RESOURCE_REMOVE_SUCCESS' });
    return resource;
  } else {
    //   error resource not found
    yield put({
      type: 'delete/RESOURCE_REMOVE_ERROR',
      error: 'not found',
    });
  }
}
