import { call, put } from 'redux-saga/effects';
import listInfos from '../listInfos';
import feathers from '../../feathers';

const _getList = async (_resource, payload) => {
  const { filters = null, sort = null, perPage = 10, page = 1 } = payload;
  let query = {};
  if (_resource.query) query = { ...query, ..._resource.query };
  if (filters) query = { ...query, ...filters };
  if (sort) query = { ...query, $sort: { ...sort } };
  let limit = perPage;
  let skip = perPage * (page - 1);
  query = { ...query, $limit: limit, $skip: skip };
  console.log('query list', query);
  return feathers
    .service(_resource.service)
    .find({ query: query })
    .then((response) => {
      console.log('list response', response);
      return response;
    });
};

export function* getList({ payload }) {
  const _list = listInfos.find((listInfo) => listInfo.name === payload.name);
  if (_list) {
    const list = yield call(_getList, _list, payload);
    yield put({ type: 'lists/GET_RESOURCE_LIST_SUCCESS' });
    return list;
  } else {
    //   error list not found
    console.log(
      'É necessário adicionar o resource no listInfos importado no topo desse arquivo'
    );
    yield put({ type: 'lists/GET_RESOURCE_LIST_ERROR', error: 'not found' });
  }
}
