import zoommers from './zoommers';
import franchisees from './franchisees';
import schools from './schools';
import students from './students';
import responsibles from './responsibles';
import news from './news';
import familyChats from './familyChats';
import materials from './materials';
import microsoftRooms from './microsoftRooms';
import admPermissions from './admPermissions';

export default [
  ...zoommers,
  ...franchisees,
  ...schools,
  ...students,
  ...responsibles,
  ...news,
  ...familyChats,
  ...materials,
  ...microsoftRooms,
  ...admPermissions,
];
