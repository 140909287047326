import { call, put } from 'redux-saga/effects';
import feathers from '../../feathers';
import checkIsOwnBase from '../../functions/checkIsOwnBase';

const _checkAuthed = async () => {
  let user = await getUser('me');
  return user;
};

const getPermissionUser = async (entityId, entityType) => {
  if (entityType == 'contributors') {
    const entity = await getEntity(entityId, entityType);
    console.log(entity);
    return entity.permissionGroup;
  } else {
    return entityType;
  }
};

export function* checkAuthed(props) {
  let user = yield call(_checkAuthed);
  let permission = yield call(
    getPermissionUser,
    user.entityId,
    user.entityType
  );
  let entity = yield call(getEntity, user.entityId, user.entityType);
  console.log(permission);
  if (user.entityType == 'advisors' || user.entityType == 'teachers') {
    let isOwnBase = yield call(checkIsOwnBase, entity.franchiseeId);
    entity.ownBase = isOwnBase;
  }
  yield put({
    type: 'authentication/CHECK_AUTHED_SUCCESS',
    payload: {
      entity: entity,
      user: user,
      permission: permission,
    },
  });

  return user;
}

const _signIn = (email, password, microsoftToken = null) => {
  if (microsoftToken == null) {
    return feathers
      .authenticate({
        strategy: 'local',
        email: email.toLowerCase(),
        password: password,
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return { error: err };
      });
  } else {
    return feathers
      .authenticate({
        strategy: 'microsoft',
        accessToken: microsoftToken,
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err', err);
        return { error: err };
      });
  }
};

const _signOut = () => {
  return feathers
    .logout()
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return { error: err };
    });
};

const getUser = (userId) => {
  return feathers
    .service('users')
    .get(userId)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return { error: err };
    });
};

const getEntity = (entityId, entityType) => {
  if (entityId !== undefined && entityType !== undefined) {
    return feathers
      .service(entityType)
      .get(entityId)
      .then((entity) => {
        return entity;
      })
      .catch((err) => {
        return { error: err };
      });
  } else {
    return { error: 'erro' };
  }
};

export function* signIn({ payload }) {
  const response = yield call(
    _signIn,
    payload.email,
    payload.password,
    payload.microsoftToken ? payload.microsoftToken : null
  );
  if (response.error) {
    yield put({
      type: 'authentication/SIGN_IN_FAILED',
      payload: { error: 'erro' },
    });
    return false;
  } else {
    const user = yield call(getUser, response.user_Id);

    yield put({
      type: 'authentication/SIGN_IN_SUCCESS',
      payload: {
        token: response.accessToken,
        user: user,
        entity: user.entity,
      },
    });
    return true;
  }
}

export function* signOut({ payload }) {
  yield call(_signOut);
  yield put({
    type: 'authentication/SIGN_OUT_SUCCESS',
    payload: {
      token: null,
      user: null,
      entity: null,
    },
  });
}
