// 'admins','contributors' 'franchisees' 'schools' 'Teacher'
const permissions = {
  dashboard: {
    list: [
      'admins',
      'contributors',
      'schools',
      'franchisees',
      'franchisee-managers',
    ],
  },
  admins: {
    list: ['admins'],
    create: ['admins'],
    edit: ['admins'],
  },
  advisors: {
    list: ['admins', 'implementations', 'franchisee-managers'],
    create: ['admins', 'implementations'],
    edit: ['admins', 'implementations'],
  },
  advisorsZoom: {
    list: ['admins', 'implementations'],
    create: ['admins', 'implementations'],
    edit: ['admins', 'implementations'],
  },
  advisorsFranchisees: {
    list: ['admins', 'implementations', 'franchisee-managers'],
    create: ['admins', 'implementations'],
    edit: ['admins', 'implementations'],
  },
  advisorsSchools: {
    list: ['admins', 'implementations', 'franchisee-managers'],
    create: ['admins', 'implementations'],
    edit: ['admins', 'implementations'],
  },
  // permissionGroups também podem ser usados, apenas contribuidores o possuem,
  // exemplo: sellers, developments, implementations e etc.
  contributors: {
    list: ['admins', 'developments'],
    create: ['admins', 'contributors'],
    edit: ['admins', 'contributors'],
  },
  franchisees: {
    list: ['admins', 'implementations', 'franchisee-managers'],
    create: ['admins', 'implementations'],
    edit: ['admins', 'implementations'],
  },
  franchiseeManagers: {
    list: ['admins', 'implementations', 'franchisee-managers'],
    create: ['admins', 'implementations'],
    edit: ['admins', 'implementations'],
  },
  franchiseeMonitors: {
    list: ['admins', 'implementations', 'franchisee-managers'],
    create: ['admins', 'implementations'],
    edit: ['admins', 'implementations'],
  },
  schools: {
    list: [
      'admins',
      'implementations',
      'sellers',
      'franchisee-managers',
      'marketings',
    ],
    create: [
      'admins',
      'implementations',
      'sellers',
      'franchisees',
      'marketings',
    ],
    edit: ['admins', 'implementations', 'sellers', 'franchisees', 'marketings'],
  },
  schoolsMonitors: {
    list: ['admins', 'implementations', 'sellers', 'marketings'],
    create: [
      'admins',
      'implementations',
      'sellers',
      'franchisees',
      'marketings',
    ],
    edit: ['admins', 'implementations', 'sellers', 'franchisees', 'marketings'],
  },
  schoolsCoordinators: {
    list: ['admins', 'implementations', 'sellers', 'marketings'],
    create: [
      'admins',
      'implementations',
      'sellers',
      'franchisees',
      'marketings',
    ],
    edit: ['admins', 'implementations', 'sellers', 'franchisees', 'marketings'],
  },
  schoolsDirectors: {
    list: ['admins', 'implementations', 'sellers', 'marketings'],
    create: [
      'admins',
      'implementations',
      'sellers',
      'franchisees',
      'marketings',
    ],
    edit: ['admins', 'implementations', 'sellers', 'franchisees', 'marketings'],
  },
  activities: {
    list: ['admins', 'schools', 'franchisees', 'sellers'],
    edit: ['admins', 'schools', 'franchisees', 'sellers'],
    onlyRead: ['franchisees'],
  },
  // Default nos Actions abaixo é usado pois o componente realiza apenas uma verificção geral
  teachers: {
    list: [
      'admins',
      'implementations',
      'sellers',
      'marketings',
      'franchisee-managers',
      'schools',
    ],
    create: [
      'admins',
      'implementations',
      'sellers',
      'marketings',
      'franchisee-managers',
      'schools',
    ],
    edit: [
      'admins',
      'implementations',
      'sellers',
      'marketings',
      'franchisee-managers',
      'schools',
    ],
  },
  teachersZoom: {
    list: ['admins', 'implementations', 'sellers', 'marketings', 'schools'],
  },
  teachersFranchisees: {
    list: [
      'admins',
      'implementations',
      'sellers',
      'marketings',
      'schools',
      'franchisee-managers',
    ],
  },
  teachersSchools: {
    list: [
      'admins',
      'implementations',
      'sellers',
      'marketings',
      'schools',
      'franchisee-managers',
    ],
  },
  programs: {
    default: ['admins'],
  },
  materials: {
    list: ['admins', 'developments'],
    create: ['admins', 'developments'],
    edit: ['admins', 'developments'],
  },
  monitors: {
    list: ['admins', 'implementations', 'franchisee-managers'],
    create: ['admins', 'implementations'],
    edit: ['admins', 'implementations'],
  },
  monitorsZoom: {
    list: ['admins', 'implementations'],
    create: ['admins', 'implementations'],
    edit: ['admins', 'implementations'],
  },
  whthemes: {
    list: ['admins', 'contributors'],
    create: ['admins', 'contributors'],
    edit: ['admins', 'contributors'],
  },
  jornadaz: {
    list: ['admins', 'developments'],
    create: ['admins', 'developments'],
    edit: ['admins', 'developments'],
  },
  whregisters: {
    list: ['admins', 'contributors'],
    create: ['admins', 'contributors'],
    edit: ['admins', 'contributors'],
  },
  gallery: {
    default: ['admins', 'contributors', 'franchisees', 'schools'],
  },
  hoTips: {
    list: ['admins', 'contributors'],
    create: ['admins', 'contributors'],
    edit: ['admins', 'contributors'],
  },
  'microsoft-rooms': {
    list: ['admins'],
    create: ['admins'],
    edit: ['admins'],
  },
  responsibles: {
    list: ['admins'],
    create: ['admins'],
    edit: ['admins'],
  },
  shorters: {
    list: ['admins', 'developments'],
    create: ['admins', 'developments'],
    edit: ['admins', 'developments'],
  },
  students: {
    list: ['admins'],
    create: ['admins'],
    edit: ['admins'],
  },
  classrooms: {
    list: ['admins', 'implementations'],
    create: ['admins', 'implementations'],
    edit: ['admins', 'implementations'],
  },
  'future-works': {
    list: ['admins', 'sellers', 'implementations'],
    create: ['admins', 'sellers', 'implementations'],
    edit: ['admins', 'sellers', 'implementations'],
  },
  news: {
    list: ['admins'],
    create: ['admins'],
    edit: ['admins'],
  },
  "family-chats": {
    list: ['admins'],
    create: ['admins'],
    edit: ['admins'],
  },
  all: {
    all: ['admins', 'contributors', 'franmchisees', 'schools'],
  },
};
const canDo = (resource, action) => {
  return permissions[resource][action];
};
export default canDo;
